import { ApiClients } from 'ApiClients';
import { FutureTbx, FutureTbxRequest } from 'power/types';
import { NYISO_ZONE } from 'power/types/enum';
import { useCallback, useState } from 'react';

const NYISO_ZONES: NYISO_ZONE[] = [NYISO_ZONE.A, NYISO_ZONE.B, NYISO_ZONE.C, NYISO_ZONE.D, NYISO_ZONE.E, NYISO_ZONE.F, NYISO_ZONE.G, NYISO_ZONE.H, NYISO_ZONE.I, NYISO_ZONE.J, NYISO_ZONE.K];

const useFutureTbx = () => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<(FutureTbx & { zone: string })[]>([]);

   const fetch = useCallback((query: Omit<FutureTbxRequest, 'zone_id'>) => {
      setLoading(true);
      setData([]);

      /* ApiClients.getInstance()
         .futureTbx({ ...query, zone_id: undefined })
         .then((res) => {
            const resData: (FutureTbx & { zone: string })[] = res.map((r) => ({ ...r, zone: NYISO_ZONE[r.zone_id] }));
            setData(resData);
         })
         .finally(() => {
            setLoading(false);
         }); */

      const promises: Promise<FutureTbx[]>[] = NYISO_ZONES.map((zone_id) => ApiClients.getInstance().futureTbx({ ...query, zone_id }));
      Promise.all(promises)
         .then((res) => {
            const resData: (FutureTbx & {zone: string})[] = res.flat().map((r) => ({...r, zone: NYISO_ZONE[r.zone_id]}))
            setData(resData);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   return { loading, data, fetch };
};

export default useFutureTbx;
