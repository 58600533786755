import addMapImages from 'power/map/helper/addMapImages';
import { usePowerMap } from 'power/map/usePowerMap';
import { NODE_MAP_DATA_COMPONENT, NODE_TYPE, TBX_VALUE_TYPE } from 'power/types/enum';
import React, { useEffect, useMemo, useState } from 'react';
import { GeoJSONSource, MapLayerMouseEvent, useMap } from 'react-map-gl';
import PriceLayer from './PriceLayer';
import images from './PriceLayer/images';

const PRICE_NODE_LAYER_ID = 'price-node-layer';
const PRICE_NODE_LABEL_LAYER_ID = `${PRICE_NODE_LAYER_ID}-label`;

const PowerNodeLayers: React.FC<{ mapId: string }> = ({ mapId }) => {
   const [loadingLayer, setLoadingLayer] = useState<boolean>(true);
   const { nodeLayers, selection, setSelection } = usePowerMap();
   const { [mapId]: map } = useMap();

   const minmax = useMemo(() => {
      if (nodeLayers?.data.features === undefined || nodeLayers?.data.features.length === 0) return [0, 0];
      const prices = nodeLayers.data.features.map((f) => f.properties.value);
      const min = Math.min(...prices);
      const max = Math.max(...prices);
      return [min, max];
   }, [nodeLayers?.data.features]);

   useEffect(() => {
      const loadImgaes = () => {
         if (map === undefined) return;
         setLoadingLayer(true);
         addMapImages(map, images, true).then(() => setLoadingLayer(false));
      // .finally(() => console.log('PowerPriceLayer.loadImgaes finished'));
      };

      const onClickClustered = (event: MapLayerMouseEvent) => {
         if (map === undefined) return;
         if (event.features === undefined || event.features.length === 0) return;

         const { cluster_id } = event.features[0].properties as any;
         const { coordinates } = event.features[0].geometry as any;
         if (!cluster_id || !coordinates) return;

         const mapboxSource = map.getSource('price-layer-source') as GeoJSONSource;

         mapboxSource.getClusterExpansionZoom(cluster_id, (err, zoom) => {
            if (err) {
               return;
            }

            map.easeTo({
               center: coordinates,
               zoom,
               duration: 500,
            });
         });
      };

      if (map) {
         map.on('style.load', loadImgaes);
         map.on('click', 'price-clusters', onClickClustered);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [map]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         // console.log('click', features);
         const nodeIds = features.filter((f) => f.layer.id === PRICE_NODE_LAYER_ID || f.layer.id === PRICE_NODE_LABEL_LAYER_ID)?.map((x) => x.properties?.id);
         // console.log('click', nodeIds);
         setSelection({ nodes: { component: nodeLayers?.query.component, ids: nodeIds } });
      };

      if (map) {
         map.on('click', [PRICE_NODE_LAYER_ID, PRICE_NODE_LABEL_LAYER_ID], onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', [PRICE_NODE_LAYER_ID, PRICE_NODE_LABEL_LAYER_ID], onClickListener as any);
         }
      };
   }, [map, nodeLayers?.query.component, setSelection]);

   if (!nodeLayers || !map || loadingLayer) return null;

   return (
      <>
         {nodeLayers.active && nodeLayers.loading === false && nodeLayers.data.features.length > 0 && (
            <PriceLayer
               layerId={PRICE_NODE_LAYER_ID}
               mapId={mapId}
               minmax={minmax}
               data={nodeLayers.data}
               selectedId={selection.nodes?.ids && selection.nodes?.ids.length > 0 ? selection.nodes?.ids[0] : undefined}
               unit={nodeLayers.query.component === NODE_MAP_DATA_COMPONENT.TBX && nodeLayers.query.tbxValueType === TBX_VALUE_TYPE.Performance ? '%' : '$'}
               zone={nodeLayers.query.nodeType === NODE_TYPE.IsoHub}
            />
         )}
      </>
   );
};

export default PowerNodeLayers;
