import { DATA_PROVIDER } from 'power/types/enum';

const BOUNDS = (iso: DATA_PROVIDER): mapboxgl.LngLatBoundsLike => {
   switch (iso) {
   case DATA_PROVIDER.NYISO:
      return [-70.96, 45.8, -80.0, 39.98];
   case DATA_PROVIDER.ISONE:
      return [-66.57, 47.74, -74.32, 40.56];
   case DATA_PROVIDER.ERCOT:
      return [-93.41474, 36.215454, -105.2432748, 25.50489];
   default:
      return [-70.96, 45.8, -80.0, 39.98];
   }
};

export default BOUNDS;