import { GeoJsonProperties } from 'geojson';
import { SymbolPaint } from 'mapbox-gl';
import addMapImages from 'power/map/helper/addMapImages';
import { FUEL_TYPE, VECTOR_LAYER_ID } from 'power/types/enum';
import { CustomLayerProps, IBaseFilter, IPowerPlantLayerProperties, IPPFilter } from 'power/types/map';
import { utils } from 'power/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, MapRef, Source } from 'react-map-gl';
import { images } from './images';
import usePowerPlantLayer from './usePowerPlantLayer';

const { layerLayout, layerPaint } = utils.map.getLayerInfo(VECTOR_LAYER_ID.POWER_PLANTS);

const POWER_PLANT_LAYER = { layerId: 'Power Plant' };

export const STATIC_DEFAULT_FILTER: IBaseFilter = {
   active: false,
};

type Props = {
  map: MapRef;
  powerPlants?: CustomLayerProps<IPowerPlantLayerProperties, IPPFilter>;
  setPopup: (location: mapboxgl.LngLat, properties: GeoJsonProperties, layer: 'iq' | 'powerPlant' | 'tl') => void;
  beforeId?: string;
};

const PowerPlantLayer: FC<Props> = ({ map, powerPlants, setPopup }) => {
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const filterpowerPlants = useMemo(() => powerPlants?.filter || STATIC_DEFAULT_FILTER, [powerPlants?.filter]);
   const { powerPlantLayer } = usePowerPlantLayer(true); // filterpowerPlants.active);

   const filterActive = filterpowerPlants.active;

   const loadImgaes = useCallback(() => {
      if (map === undefined) return;
      setLoadingImages(true);
      addMapImages(map, images).then(() => setLoadingImages(false));
   }, [map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);

   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;
         const feature = features[0];
         // console.log('click', feature);
         setPopup(ev.lngLat, feature.properties, 'powerPlant');
      };

      if (map) {
         map.on('click', [POWER_PLANT_LAYER.layerId], onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', [POWER_PLANT_LAYER.layerId], onClickListener as any);
         }
      };
   }, [map, setPopup]);

   const layerFilter = useMemo(() => {
      if (powerPlants?.filter === undefined) return [''];
      const { active, fuelTypes } = powerPlants.filter;
      if (!active) return ['all'];

      if (active) {
         if (fuelTypes.includes(FUEL_TYPE.Undefined)) {
            return ['all'];
         }

         const lf = ['all'] as any[];
         // 1. method: b_f_type base filter
         lf.push(['in', ['get', 'b_f_type'], ['literal', fuelTypes]]);
         return lf;
      }

      // lf.push(['==', ['get', 'b_status'], status]);
   }, [powerPlants?.filter]);

   // console.log('powerPlantLayer', powerPlantLayer);

   if (loadingImages || powerPlantLayer === undefined || filterActive === false) return null;

   return (
      <>
         {filterpowerPlants.active && powerPlantLayer && (
            <Source id={POWER_PLANT_LAYER.layerId} type="geojson" data={powerPlantLayer}>
               <Layer
                  id={POWER_PLANT_LAYER.layerId} source={POWER_PLANT_LAYER.layerId} type="symbol"
                  layout={layerLayout as any} paint={layerPaint as SymbolPaint} filter={layerFilter} />
            </Source>
         )}
      </>
   );
};

export default PowerPlantLayer;
