import { GeoJsonProperties } from 'geojson';
import { DATA_PROVIDER } from 'power/types/enum';
import TL_VOLTAGE from 'power/types/enum/TL_VOLTAGE';
import { CustomLayerProps, ITransmissionLinesLayerProperties } from 'power/types/map';
import ITLFilter from 'power/types/map/ITLFilter';
import { useIso } from 'providers/useIso';
import React, { useMemo } from 'react';
import { MapRef } from 'react-map-gl';
import IsoneTransmissionLinesLayer from './IsoneTransmissionLinesLayer';
import NyisoTransmissionLinesLayer from './NyisoTransmissionLinesLayer';

export const TL_DEFAULT_FILTER: ITLFilter = {
   active: false,
   voltages: [TL_VOLTAGE['0 - 68'], TL_VOLTAGE['69 - 114'], TL_VOLTAGE['115 - 274'], TL_VOLTAGE['275 - 344'], TL_VOLTAGE['345 - 499'], TL_VOLTAGE['500+']],
};

type Props = {
  map: MapRef;
  layerProps: CustomLayerProps<ITransmissionLinesLayerProperties, ITLFilter>;
  setPopup: (location: mapboxgl.LngLat, properties: GeoJsonProperties, layer: 'iq' | 'powerPlant' | 'tl') => void;
  beforeId?: string;
};

const TransmissionLinesLayer: React.FC<Props> = ({ map, layerProps, setPopup, beforeId }) => {
   const { iso } = useIso();
   const filter = useMemo(() => layerProps?.filter || TL_DEFAULT_FILTER, [layerProps]);

   if (!filter.active) return null;

   if (iso === DATA_PROVIDER.NYISO) return (
      <NyisoTransmissionLinesLayer
         map={map} layerProps={layerProps} setPopup={setPopup}
         beforeId={beforeId} />
   );

   if (iso === DATA_PROVIDER.ISONE) return (
      <IsoneTransmissionLinesLayer
         map={map} layerProps={layerProps} setPopup={setPopup}
         beforeId={beforeId} />
   );

   return null;
};

export default TransmissionLinesLayer;
