import { Flex, Select, Spin /* type SelectProps */ } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ILocationSimple } from 'power/types';
import { NODE_TYPE } from 'power/types/enum';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import classes from './SelectNodev2.module.scss';

const TagNames = {
   [NODE_TYPE.IsoBus]: 'B',
   [NODE_TYPE.DraftNode]: 'N',
   [NODE_TYPE.IsoHub]: 'H',
};

interface ISelectNodev2 /* extends SelectProps */ {
  value?: number;
  fetcher: () => Promise<ILocationSimple[]>;
  onChange: (selectedNodeId: number, selectedHub: ILocationSimple) => void;
  disabled?: boolean;
}

const SelectNodev2: FC<PropsWithChildren<ISelectNodev2>> = ({ value, fetcher, onChange, disabled }) => {
   const [fetching, setFetching] = useState(false);
   const [options, setOptions] = useState<ILocationSimple[]>();

   useEffect(() => {
      setFetching(true);
      fetcher()
         .then((r) => {
            setOptions(r);
         })
         .finally(() => setFetching(false));
      
   }, [fetcher]);

   const filterOption = (input: string, option?: any ) => {
      const location = (option as any).payload as ILocationSimple;
      return location.name.toLowerCase().includes(input.toLowerCase());
   }

   
   return (
      <Select
         value={options && !fetching ? value : undefined}
         size="small"
         variant="borderless"
         showSearch
         allowClear={false}
         optionFilterProp="payload"
         filterOption={filterOption}
         notFoundContent={fetching ? <Spin size="small" /> : null}
         onChange={(selectedNodeId: number, option: DefaultOptionType | DefaultOptionType[]) => {
            onChange(selectedNodeId, (option as any).payload as ILocationSimple);
         }}
         loading={fetching}
         disabled={disabled}
      >
         {options?.map((node: ILocationSimple) => {
            const icon = (TagNames as any)[node.type] ?? 'N';
            let iconText = icon;
            if(iconText === 'H') {
               if(node.name.startsWith('.Z.')) iconText = 'Z';
            }
            else if(iconText === 'N') {
               if(node.name.startsWith('.I.')) iconText = 'I';
            }

            return (
               <Select.Option key={node.id} value={node.id} payload={node}>
                  <Flex justify="flex-start" align="center" style={{paddingRight: 14}}>
                     

                     {node.type && (
                        <span className={classes[`icon-${icon}`]}>
                           <span className={classes.tag}>{iconText}</span>
                        </span>
                     )}

                     <span>{node.name}</span>
                  </Flex>

                  {/* {node.provider && <span className={classes.provider}>{DATA_PROVIDER[node.provider]}</span>} */}
               </Select.Option>
            );
         })}
      </Select>
   );
};

export default SelectNodev2;
