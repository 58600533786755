import { Column } from '@ant-design/plots';
import columnChartConfig from 'configs/antd/charts';
import { IChartData } from 'power/types';
import { TIME_GRANULARITY } from 'power/types/enum';
import { FC } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';
import { normalizeNumber } from 'utils/math';
import classes from './TimeSeriesChart.module.scss';

type Props = {
  data: IChartData[];
  height?: string | number;
  unit?: string;
};

const chartConfig = columnChartConfig('', '');

const TimeSeriesChart: FC<Props> = ({ data, height, unit }) => (
   <div className={classes.chart}>
      <Column
         style={{
            height,
         }}
         {...chartConfig}
         className={undefined}
         data={data}
         xField="x"
         yField="y"
         seriesField="group"
         isGroup
         /* color={(datum) => (datum?.group?.includes('DA') ? '#5AD8A6' : '#2D8C6C')} */
         /* tooltip={false} */
         appendPadding={0}
         legend={{
            // ...chartConfig.legend,
            position: 'top-right',
            padding: [0, 0, 0, 0],
         }}
         yAxis={{
            label: {
               formatter: (d) => normalizeNumber(d as unknown as number),
            },
         }}
         xAxis={{
            label: {
               formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.yearly),
            },
         }}
         tooltip={{
            title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.yearly),
         }}
      />
   </div>
);

export default TimeSeriesChart;
