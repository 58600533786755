import { SymbolLayout, SymbolPaint } from 'mapbox-gl';

const LAYOUT: SymbolLayout = {
   'text-field': ['get', 'Zone'], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 18, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0],
};

const LAYOUT_ISONE: SymbolLayout = {
   'text-field': ['get', 'Zone Kod'], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 18, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0],
};

const LAYOUT_ERCOT: SymbolLayout = {
   'text-field': ['get', 'zone_name'], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 18, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0],
};

const PAINT: SymbolPaint = {
   // 'text-color': '#fff',
   'text-color': '#597EF7',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, LAYOUT_ISONE, LAYOUT_ERCOT, PAINT };
