import { ConfigProvider, ThemeConfig, theme } from 'antd';
import React from 'react';

export const AntdConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   /**
   * TODO custom theme config
   */
   const antdThemeConfig: ThemeConfig = {
      algorithm: theme.defaultAlgorithm,
      token: {
         fontSize: 14,
         fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
         controlHeight: 36,
         controlHeightLG: 44,
         controlHeightSM: 26,
         borderRadius: 4,
         borderRadiusLG: 6,
         borderRadiusOuter: 3,
         borderRadiusSM: 3,
         borderRadiusXS: 1,
         lineWidth: 1,
         colorBorder: '#C9C1C1',
         colorBorderSecondary: '#f4f2f2',
         colorPrimary: '#42B389',
         screenLGMax: 1280,
         screenXL: 1281,
         screenXLMin: 1281,
         colorBgBase: '#ffffff',
      },
      components: {
         Layout: {
            headerBg: '#ffffff',
            bodyBg: '#ffffff',
         },

         Menu: {
            // if you use "dark" theme on menu
            itemBg: '#ffffff', // colorBgBase -3% lightness
            subMenuItemBg: '#ffffff', // colorBgBase -6% lightness
            /* size: 48, */
         },
         Tabs: {
            cardBg: '#f4f2f2',
         },
      },
   };

   return (
      <>
         <ConfigProvider theme={antdThemeConfig}>{children}</ConfigProvider>
      </>
   );
};
