/* eslint-disable react/no-array-index-key */
import React, { PropsWithChildren } from 'react';
import { MapProps, MapProvider } from 'react-map-gl';
import SiderRightControl from './controls/SiderRightControl';
import PowerMapDebug from './debug';
import CapacityLayers from './layers/CapacityLayers';
import CapacityStaticLayers from './layers/CapacityStaticLayers';
import NodeLayer from './layers/NodeLayer';
import StaticLayers from './layers/StaticLayers';
import PowerMapView, { PowerMapViewProps } from './MapView';

type PowerMapProps = PowerMapViewProps & {
  sider?: { container?: React.ReactElement; onClose?: () => void; width?: string | number };
  customMapProps?: Partial<MapProps>;
  customSiderRightControls?: React.ReactNode[];
};

const PowerMap: React.FC<PropsWithChildren<PowerMapProps>> = ({ id, controlPositions, sider, customMapProps, customSiderRightControls, children }) => (
   <MapProvider>
      <PowerMapView id={id} controlPositions={controlPositions} customMapProps={customMapProps}>
         <PowerMapDebug mapId={id} />
         <CapacityLayers mapId={id} />
         <CapacityStaticLayers mapId={id} />
         <NodeLayer mapId={id} />
         <StaticLayers mapId={id} />
         <SiderRightControl mapId={id} customSiderRightControls={customSiderRightControls} />
         {children}
      </PowerMapView>
   </MapProvider>
);

export default PowerMap;
