// import { PowerMap } from 'power/components/maps';
import { GenerationPocketBindingContingency } from 'power/types';
import { FC, Fragment, useMemo } from 'react';
import classes from './index.module.scss';

type Props = {
  pockets: { id: number; name: string }[];
  bindingContingencies: GenerationPocketBindingContingency[];
};

const BindingContingenciesTable: FC<Props> = ({ pockets, bindingContingencies }) => {
   const dataList = useMemo(() => {
      const data = bindingContingencies.map((d) => {
         const pocketName = pockets.find((p) => p.id === d.generation_pocket_id)?.name ?? '';

         return {
            ...d,
            pocketName,
         };
      });
      return data;
   }, [bindingContingencies, pockets]);

   if (bindingContingencies.length === 0) return null;

   return (
      <div className={classes.table}>
         <h4 style={{ textAlign: 'center' }}>Binding Contingencies</h4>
         <table>
            <thead>
               <tr>
                  <th>Pocket</th>
                  <th>Contingency Name</th>
                  <th>Monitored Line</th>
                  <th>Tripped Lines</th>
               </tr>
            </thead>
            <tbody>
               {dataList.map((d, i) => (
                  <Fragment key={`${i}-${d.generation_pocket_id}`}>
                     {d.tripped_lines.map((r, j) => {
                        const firstPocketRow = dataList.findIndex((x) => x.generation_pocket_id === d.generation_pocket_id) === i;
                        const pocketTrippedCount = dataList.filter((x) => x.generation_pocket_id === d.generation_pocket_id).reduce((acc, curr) => acc + curr.tripped_lines.length, 0);

                        return (
                           <tr key={`${i}-${j}-${r}`}>
                              {j === 0 && (
                                 <>
                                    {firstPocketRow && <td rowSpan={pocketTrippedCount}>{d.pocketName}</td>}
                                    <td rowSpan={d.tripped_lines.length}>{d.contingency_name}</td>
                                    <td style={{ width: '35%' }} rowSpan={d.tripped_lines.length}>
                                       {d.monitored_line}
                                    </td>
                                 </>
                              )}
                              <td style={{ width: '40%' }}>{r}</td>
                           </tr>
                        );
                     })}
                  </Fragment>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default BindingContingenciesTable;
