import { GenerationPocketConstraintResponse, GenerationPocketInfoResponse } from 'power/types';
import { useMemo, useState } from 'react';
import { useAverageCurtailment } from './useAverageCurtailment';
import { useBindingContingencies } from './useBindingContingencies';
import { useConstraints } from './useConstraints';
import { useDescriptions } from './useDescriptions';
import { useInfos } from './useInfos';
import { useLargeLoads } from './useLargeLoad';
import { usePlants } from './usePlants';
import { usePockets } from './usePockets';
import { useSummary } from './useSummary';
import { useTransmissionLines } from './useTransmissionLines';

export const useData = () => {
   const { loadingPockets, pockets } = usePockets();
   const { loadingConstraintList, constraintList } = useConstraints();
   const { loadingInfoList, infoList } = useInfos();
   const { loadingTransmissionLines, transmissionLinesList } = useTransmissionLines();
   const { loadingPlants, plantsList } = usePlants();
   const { loadingLargeLoads, largeLoadsList } = useLargeLoads();
   const { loadingSummary, summary } = useSummary();
   const { loadingBindingContingencies, bindingContingencies } = useBindingContingencies();
   const { loadingDescription, descriptionPocket, descriptionRegion } = useDescriptions();
   const { loadingAverageCurtailment, averageCurtailments } = useAverageCurtailment();

   const [selectedPocket, setSelectedPocket] = useState<number | null>(null);

   const selectedConstraints: GenerationPocketConstraintResponse = useMemo(() => {
      if (!selectedPocket) return [];
      return constraintList.map((d) => ({ target_year: d.target_year, constraints: d.constraints.filter((x) => x.generation_pocket_id === selectedPocket) }));
   }, [selectedPocket, constraintList]);

   const selectedInfos: GenerationPocketInfoResponse = useMemo(() => {
      if (!selectedPocket) return [];
      return infoList.map((x) => ({ target_year: x.target_year, info: x.info.filter((y) => y.generation_pocket_id === selectedPocket) }));
   }, [selectedPocket, infoList]);

   return {
      loading: loadingPockets || loadingConstraintList || loadingInfoList || loadingTransmissionLines || loadingPlants || loadingLargeLoads || loadingSummary || loadingBindingContingencies || loadingDescription || loadingAverageCurtailment,
      pockets,
      selectedPocket,
      setSelectedPocket,

      constraintList,
      selectedConstraints,

      infoList,
      selectedInfos,

      transmissionLinesList,
      plantsList,
      largeLoadsList,
      summary,
      bindingContingencies,
      descriptionPocket,
      descriptionRegion,
      averageCurtailments,
   };
};
