import { PowerCol, PowerRow } from 'power/components/base/grid';
import LazyComponent from 'power/components/base/LazyComponent';
import { FutureTimeseriesRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE, NYISO_ZONE, TIME_GRANULARITY } from 'power/types/enum';
import { useState } from 'react';
import FutureTimeseriesFilter from './FilterBar';
import { FutureTimeseriesChart } from './FutureTimeseriesChart/FutureTimeseriesChart';
import classes from './index.module.scss';

const FutureTimeseriesTab = () => {
   const [query, setQuery] = useState<FutureTimeseriesRequest>({ market_type: MARKET_TYPE.DayAhead, scenario: FUTURE_SCENARIO.REF, zone_id: NYISO_ZONE.K, time_granularity: TIME_GRANULARITY.daily, start_date: '2028-01-01', end_date: '2028-12-31' });

   return (
      <div className={classes.fullContainer}>
         <div className={classes.filterBar}>
            <FutureTimeseriesFilter loading={false} query={query} onSubmit={setQuery} />
         </div>
         <div className={classes.container}>
            <PowerRow gutter={[16, 16]}>
               <PowerCol span={24}>
                  <LazyComponent key={JSON.stringify(query)}>
                     <FutureTimeseriesChart query={query} />
                  </LazyComponent>
               </PowerCol>
            </PowerRow>
         </div>
      </div>
   );
};

export default FutureTimeseriesTab;
