// import { PowerMap } from 'power/components/maps';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  headers: string[];
  keys: string[];
  rows: { id: string; allValues: (number | string)[][] }[];
};

const TableConstraint: FC<Props> = ({ headers, keys, rows }) => {
   if (!rows.length || !keys.length || !rows[0].allValues.length || !rows[0].allValues[0].length) return null;

   return (
      <div className={classes.table}>
         <table>
            <thead>
               <tr>
                  <th rowSpan={2} className={classes.firstHeader}>{headers[0]}</th>
                  <th colSpan={keys.length}>
                     {headers[1]}
                  </th>
               </tr>
               <tr>
                  {keys.map((key, index) => (
                     <th key={index}>{key}</th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {rows.map((row, index) => (
                  <tr key={row.id}>
                     <td>{row.id}</td>
                     {row.allValues.map((values, index) => values.map((value, index) => <td key={index} className={classes.values}>{value}</td>))}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default TableConstraint;
