import { ApiClients } from 'ApiClients';
import PATHS from 'power/PATHS';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ActivateAccount.module.scss';
import ActivateAccountForm from './ActivateAccountForm/ActivateAccountForm';

const ActivateAccount = () => {
   const [loading, setLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

   const navigate = useNavigate();
   const { key } = useParams();

   const onActivateAccount = useCallback(
      async (firstName: string, lastName: string, password: string) => {
         if (key === undefined) return navigate(PATHS.Auth_Login);

         setLoading(true);
         setErrorMessage(undefined);
         try {
            const res = await ApiClients.getInstance().activateAccount(firstName, lastName, password, key);
            if (res === false) throw new Error('Invalid key or new password');
            else navigate(PATHS.Auth_Login);
         } catch (error: any) {
            console.log('error', error);
            setErrorMessage('Invalid key or new password');
            setLoading(false);
         }
      },
      [key, navigate],
   );

   return (
      <div className={styles.content}>
         <div className={styles.form}>
            <div className={styles.logo} />
            <h2 className={styles.title}>Activate Account</h2>
            <ActivateAccountForm loading={loading} onActivateAccount={onActivateAccount} errorMessage={errorMessage} />
         </div>
      </div>
   );
};

export default ActivateAccount;
