import { ApiClients } from 'ApiClients';
import { IICAPFuture, IICAPFutureRequest } from 'power/types';
import { useCallback, useEffect, useState } from 'react';
import { useICAPFutureYears } from './useICAPFutureYears';

export const useICAPFutureData = () => {
   const { loading: loaidingYears, years } = useICAPFutureYears();
   const [query, setQuery] = useState<IICAPFutureRequest>({ period_year: 2030, period_month: 1 });

   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<IICAPFuture[] | undefined>(undefined);

   const handleData = useCallback((query: IICAPFutureRequest) => {
      setLoading(true);
      setData(undefined);
      ApiClients.getInstance()
         .icapFuture(query)
         .then((res) => {
            setData(res);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   useEffect(() => {
      if (loaidingYears) return;
      handleData(query);
   }, [handleData, loaidingYears, query, years]);

   return {
      data,
      loading,
      years,
      query,
      setQuery,
   };
};
