import { ApiClients } from 'ApiClients';
import { GenerationPocketTransmissionLine } from 'power/types';
import { useEffect, useState } from 'react';

export const useTransmissionLines = () => {
   const [loadingTransmissionLines, setLoadingTransmissionLines] = useState<boolean>(true);
   const [transmissionLinesList, setTransmissionLinesList] = useState<GenerationPocketTransmissionLine[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketTransmissionLineList()
         .then((res) => {
            setTransmissionLinesList(res);
         })
         .finally(() => setLoadingTransmissionLines(false));
   }, []);

   return { loadingTransmissionLines, transmissionLinesList };
}
