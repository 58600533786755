import { Column } from '@ant-design/plots';
import { Radio } from 'antd';
import CardPanel from 'components/ui/CardPanel';
import columnChartConfig from 'configs/antd/charts';
import { usePowerTimeSeries } from 'modules/HomeMap/SiderControl/container/content/nodeInfo/charts/PowerTimeSeries/usePowerTimeSeries';
import { API_ROUTES } from 'power/constants';
import { NODE_MAP_DATA_COMPONENT, TBX_VALUE_TYPE, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';
import { normalizeNumber } from 'utils/math';

const chartConfig = columnChartConfig('', '');

type Props = {
  nodeId: number;
  tbxValueType: TBX_VALUE_TYPE;
  tbxComponent?: TXBX_COMPONENT;
};
const TBxTimeSeriesChart: FC<Props> = ({ nodeId, tbxValueType, tbxComponent = TXBX_COMPONENT.Tb4 }) => {
   const { loading, fetch, data } = usePowerTimeSeries(API_ROUTES.NODE.TimeSeries);

   const [tbxComponentLocal, setTbxComponentLocal] = useState<TXBX_COMPONENT>(tbxComponent);

   useEffect(() => {
      fetch({ component: NODE_MAP_DATA_COMPONENT.TBX, nodeId, tbxValueType, hour: tbxComponentLocal });
   }, [fetch, nodeId, tbxComponentLocal, tbxValueType]);

   return (
      <CardPanel
         title={`TB${tbxComponentLocal} ${tbxValueType === TBX_VALUE_TYPE.Performance ? 'Performance' : 'Average'} Trend`}
         defaultViewMode="chart"
         status={loading ? 'loading' : 'success'}
         fullScreen
         downloadURL={data?.downloadUrl}
         refreshClick={() => fetch({ component: NODE_MAP_DATA_COMPONENT.TBX, nodeId, tbxValueType, hour: tbxComponentLocal }, true)}
      >
         {() => (
            <>
               <div className="card-panel-filter-bar">
                  <Radio.Group value={tbxComponentLocal} onChange={(e) => setTbxComponentLocal(e.target.value)}>
                     <Radio value={TXBX_COMPONENT.Tb1}>TB1</Radio>
                     <Radio value={TXBX_COMPONENT.Tb2}>TB2</Radio>
                     <Radio value={TXBX_COMPONENT.Tb3}>TB3</Radio>
                     <Radio value={TXBX_COMPONENT.Tb4}>TB4</Radio>
                  </Radio.Group>
               </div>
               <div style={{ margin: '-10px 8px', color: '#808080' }}>{tbxValueType === TBX_VALUE_TYPE.Performance ? '%' : 'MW/h'}</div>
               <Column
                  style={{
                     height: 435,
                  }}
                  {...chartConfig}
                  className={undefined}
                  data={data?.result?.data || []}
                  xField="x"
                  yField="y"
                  seriesField="group"
                  isGroup
                  // color={(datum) => (datum?.group?.includes('DA') ? '#5AD8A6' : '#2D8C6C')}
                  /* tooltip={false} */
                  appendPadding={0}
                  legend={{
                     // ...chartConfig.legend,
                     position: 'top',
                     padding: [0, 0, 0, 0],
                  }}
                  yAxis={{
                     label: {
                        formatter: (d) => normalizeNumber(d as unknown as number),
                     },
                  }}
                  xAxis={{
                     label: {
                        formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.yearly),
                     },
                  }}
                  tooltip={{
                     title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.yearly),
                  }}
               />
            </>
         )}
      </CardPanel>
   );
};

export default TBxTimeSeriesChart;
