import { CircleLayout, CirclePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

const scale = 0.3;
const LAYOUT: SymbolLayout = {
   'icon-size': 1 * scale,
   // type: 'Wind' | 'Solar' | 'Hydro' | 'HQ Imports';
   // 'icon-image': 'Wind-image-powerdev',
   'icon-image': [
      'case',
      ['==', ['get', 'type'], 'Wind'],
      'Wind-image-powerdev',
      ['==', ['get', 'type'], 'Solar'],
      'Solar-image-powerdev',
      ['==', ['get', 'type'], 'Hydro'],
      'Hydroelectric-image-powerdev',
      'Other-image-powerdev',
      // 'default-icon',
   ],
   'icon-allow-overlap': true,
};

const LAYOUT_CIRCLE: CircleLayout = {
};
const PAINT_CIRCLE: CirclePaint = {
   'circle-radius': 10,
   'circle-color': [
      'case',
      ['==', ['get', 'type'], 'Wind'],
      '#14e0f1',
      ['==', ['get', 'type'], 'Solar'],
      '#feff00',
      '#FFFFFF',
   ],
   'circle-stroke-color': '#000000',
   'circle-stroke-width': 1,
};
const LAYOUT_CIRCLE_TEXT: SymbolLayout = {
   'text-field': [
      'case', 
      ['==', ['get', 'case_type'], 'PolicyCaseHigherDemand'],
      'H',
      ['==', ['get', 'case_type'], 'PolicyCaseLowerDemand'],
      'L',
      ['==', ['get', 'case_type'], 'PolicyCaseLowerAndHigherDemand'],
      '+',
      '',
   ],
   'text-size': 14, // Yazı boyutu
   // font weight
   'text-font': ['Arial Unicode MS Bold'],
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   /* 'text-offset': [0, -.25], */
   'icon-allow-overlap': true,
   'text-allow-overlap': true,
};
const PAINT_CIRCLE_TEXT: SymbolPaint = {
   'text-color': '#000000',
};


const PAINT: SymbolPaint = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT, LAYOUT_CIRCLE, PAINT_CIRCLE, LAYOUT_CIRCLE_TEXT, PAINT_CIRCLE_TEXT };
