import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { FC } from 'react';
import InfoBar from './InfoBar';
import TabsContent from './Tabs';

type Props = {
  query: Partial<INodeInfoRequest>;
  data: INodeInfoResponse;
};

const Container: FC<Props> = ({ query, data }) => (
   <>
      <InfoBar data={data} />
      <div className="page-tab-container">
         <TabsContent query={query} data={data} />
      </div>
   </>
);

export default Container;
