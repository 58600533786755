import { ApiClients } from 'ApiClients';
import { GenerationPocketDescription, GenerationPocketRegionDescription } from 'power/types';
import { useEffect, useState } from 'react';

export const useDescriptions = () => {
   const [loadingDescription, setLoadingDescription] = useState<boolean>(true);
   const [descriptionPocket, setDescriptionPocket] = useState<GenerationPocketDescription[]>([]);
   const [descriptionRegion, setDescriptionRegion] = useState<GenerationPocketRegionDescription[]>([]);

   useEffect(() => {
      const p1 = ApiClients.getInstance().generationPocketDescriptionPockets();
      const p2 = ApiClients.getInstance().generationPocketDescriptionRegions();
      Promise.all([p1, p2])
         .then(([res1, res2]) => {
            setDescriptionPocket(res1);
            setDescriptionRegion(res2);
         })
         .finally(() => setLoadingDescription(false));
   }, []);

   return { loadingDescription, descriptionPocket, descriptionRegion };
};
