import { ApiClients } from 'ApiClients';
import { GenerationPocketPlant } from 'power/types';
import { useEffect, useState } from 'react';

export const usePlants = () => {
   const [loadingPlants, setLoadingPlants] = useState<boolean>(true);
   const [plantsList, setPlantsList] = useState<GenerationPocketPlant[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketPlantList()
         .then((res) => {
            setPlantsList(res);
         })
         .finally(() => setLoadingPlants(false));
   }, []);

   return { loadingPlants, plantsList };
}
