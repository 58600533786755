// import { PowerMap } from 'power/components/maps';
import LazyComponent from 'power/components/base/LazyComponent';
import { FuturePriceRequest } from 'power/types';
import { NYISO_ZONE } from 'power/types/enum';
import { FC } from 'react';
import classes from './index.module.scss';
import FutureTbxChart from './TbxChart';
import FutureTbxTimeseriesChart from './TbxTimeseriesChart';

const NYISO_ZONES: NYISO_ZONE[] = [NYISO_ZONE.A, NYISO_ZONE.B, NYISO_ZONE.C, NYISO_ZONE.D, NYISO_ZONE.E, NYISO_ZONE.F, NYISO_ZONE.G, NYISO_ZONE.H, NYISO_ZONE.I, NYISO_ZONE.J, NYISO_ZONE.K];

type Props = {
  query: FuturePriceRequest;
};

const FutureTbx: FC<Props> = ({ query }) => (
   <div className={classes.container}>
      <FutureTbxChart query={query} />
      {NYISO_ZONES.map((zone_id) => (
         <LazyComponent key={zone_id}>
            <FutureTbxTimeseriesChart key={zone_id} zone_id={zone_id} query={query} />
         </LazyComponent>
      ))}
   </div>
);

export default FutureTbx;
