import { useContent } from 'content';
import LazyComponent from 'power/components/base/LazyComponent';
import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { MARKET_TYPE, PRICE_COMPONENT } from 'power/types/enum';
import { FC } from 'react';
import DARTSpreadTimeSeries from '../lmp/components/DARTSpreadTimeSeries';
import YearlySum from '../lmp/components/YearlySum';
import TopFiveCongestionConstraints from '../mcc/components/TopFiveCongestionConstraints';
import PerformanceTimeSeriesChart from '../tbx/components/PerformanceTimeSeriesChart';

type Props = {
  query: Partial<INodeInfoRequest>;
  data: INodeInfoResponse;
};

const OverviewTab: FC<Props> = ({ query, data }) => {
   const { t } = useContent();

   return (
      <PowerRow gutter={[16, 16]}>
         <PowerCol
            xs={24} sm={24} md={16}
            lg={16}>
            <LazyComponent>
               <DARTSpreadTimeSeries nodeId={data.node.id} provider={data.node.provider} />
            </LazyComponent>
         </PowerCol>

         <PowerCol
            xs={24} sm={24} md={8}
            lg={8}>
            <LazyComponent>
               <YearlySum
                  nodeId={data.node.id} component={PRICE_COMPONENT.LMP} title={t('lmpOverview.lmpSummary')}
                  info={t('lmpOverview.lmpSummaryChartInfo')} />
            </LazyComponent>
         </PowerCol>

         {!data.node.disabledTabs.includes('tbx') && (
            <PowerCol
               xs={24} sm={24} md={24}
               lg={24}>
               <LazyComponent>
                  <PerformanceTimeSeriesChart
                     filter={{
                        id: data.node.id,
                        hubId: data.node.hubId,
                        // component: TXBX_COMPONENT.Tb4,
                        // periodType: TIME_GRANULARITY.yearly,
                        // marketType: MARKET_TYPE.Realtime,
                        // sumType: DATA_SUM_TYPE.Daily,
                        // startDate: '2018-01-01T00:00:00.000',
                        // endDate: '2024-05-02T00:00:00.000',
                     }}
                     marketType={MARKET_TYPE.DayAhead}
                     marketTypeFilter
                  />
               </LazyComponent>
            </PowerCol>
         )}

         {!data.node.disabledTabs.includes('mcc') && (
            <PowerCol
               xs={24} sm={24} md={24}
               lg={24}>
               <TopFiveCongestionConstraints nodeId={data.node.id} />
            </PowerCol>
         )}
      </PowerRow>
   );
};

export default OverviewTab;
