import { Line } from '@ant-design/plots';
import { Radio, Table } from 'antd';
import { ApiClients } from 'ApiClients';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import columnChartConfig from 'configs/antd/charts';
import { useContent } from 'content';
import { extent } from 'd3';
import { chain } from 'lodash';
import { API_ROUTES } from 'power/constants';
import { MARKET_TYPE, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { parseDateByTimeGranularity, setChartRange } from 'utils/data';
import { normalizeNumber } from 'utils/math';

type Props = {
  nodeId: number;
};

const WeightedSeries: FC<Props> = ({ nodeId }) => {
   const { t } = useContent();

   // const [range, setRange] = useState<number[]>(null as any);

   const [marketType, setMarketType] = useState(MARKET_TYPE.Realtime);

   const chartConfig = useMemo(() => columnChartConfig('Monthly', '$/MWh'), []);

   const { isFetching, data, error, refetch } = useQuery([API_ROUTES.LMP.WeightedSeries, marketType, nodeId], () => ApiClients.getInstance().lmpWeightedSeries({
      nodeId,
      dayAheadMarket: marketType === MARKET_TYPE.DayAhead,
      realtimeMarket: marketType === MARKET_TYPE.Realtime,
   }).then((res) => {
      const [min, max] = extent((res.result as any).data.flatMap((d: any) => d.y));

      return {
         ...res,
         result: res.result?.data
            .map((d) => ({
               ...d,
               x: `${d.x}`,
            }))
            .sort((a, b) => {
               // first DA, second RT
               if ((a.group as any) < (b.group as any)) return -1;
               if ((a.group as any) > (b.group as any)) return 1;
               return 0;
            }),

         range: setChartRange(min as any, max as any),

         tableData: Object.entries(
            chain(
               res.result?.data.map((d) => ({
                  date: d.x.split('T')[0],
                  marketType: d.group?.split('-')[0],
                  group: d.group?.split('-')[1],
                  value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
               })),
            )
               .groupBy((d) => `${d.date}--${d.marketType}`)
               .value(),
         ).map(([group, data]) => ({
            date: group.split('--')[0],
            marketType: group.split('--')[1],
            ...(data.reduce(
               (acc, cur) => ({
                  ...acc,
                  [cur.group as any]: cur.value,
               }),
               {},
            ) as { date: string; marketType: string; SOLAR: number; LMP: number; WIND: number }[]),
         })),
      };
   }),
   );

   /* useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]); */

   return (
      <CardPanel
         title={t('lmpOverview.weightedSeries')}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : (data?.result?.length as any) < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         downloadURL={data?.downloadUrl}
         refreshClick={refetch}
         info={t('lmpOverview.weightedSeriesChartInfo')}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {/* {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data?.range?.[0]}
                              max={data?.range?.[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )} */}

                  <Radio.Group value={marketType} onChange={(e) => setMarketType(e.target.value)}>
                     <Radio value={MARKET_TYPE.DayAhead}>{t('base.da')}</Radio>

                     <Radio value={MARKET_TYPE.Realtime}>{t('base.rt')}</Radio>
                  </Radio.Group>
               </div>

               {viewMode === 'chart' && (
                  <Line
                     {...(chartConfig as any)}
                     data={data?.result ?? []}
                     xField="x"
                     yField="y"
                     seriesField="group"
                     xAxis={{
                        ...chartConfig.xAxis,
                        tickCount: 12,
                        label: {
                           formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
                        },
                     }}
                     yAxis={{
                        ...chartConfig.yAxis,
                        // min: range?.[0],
                        // max: range?.[1],
                     }}
                     point={{ size: 3, shape: 'circle' }}
                     tooltip={{
                        shared: true,
                        title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.monthly),
                        formatter: (d) => ({
                           name: d.group,
                           value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                        }),
                     }}
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.date'),
                           dataIndex: 'date',
                           key: 'date',
                        },
                        {
                           title: t('base.marketType'),
                           dataIndex: 'marketType',
                           key: 'marketType',
                        },
                        {
                           title: 'SOLAR',
                           dataIndex: 'SOLAR',
                           key: 'SOLAR',
                        },
                        {
                           title: 'LMP',
                           dataIndex: 'LMP',
                           key: 'LMP',
                        },
                        {
                           title: 'WIND',
                           dataIndex: 'WIND',
                           key: 'WIND',
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.tableData ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default WeightedSeries;
