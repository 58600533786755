import { ApiClients } from 'ApiClients';
import { GenerationPocketInfoResponse } from 'power/types';
import { useEffect, useState } from 'react';

export const useInfos = () => {
   const [loadingInfoList, setLoadingInfoList] = useState<boolean>(true);
   const [infoList, setInfoList] = useState<GenerationPocketInfoResponse>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketInfoList()
         .then((res) => {
            setInfoList(res);
         })
         .finally(() => setLoadingInfoList(false));
   }, []);

   return { loadingInfoList, infoList };
}
