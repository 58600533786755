import { Tabs, TabsProps } from 'antd';
import { useState } from 'react';

const TAB_ITEMS: TabsProps['items'] = [
   {
      key: '#node',
      label: 'Node',
      children: <div>Node</div>,
   },
   {
      key: '#substation',
      label: 'Substation',
      children: <div>Substation</div>,
   },
];

const TestTabs = () => {
   const [activeKey, setActiveKey] = useState<string>(TAB_ITEMS[0].key);

   return (
      <>
         <Tabs
            style={{ paddingBottom: 32 }} activeKey={activeKey} centered
            type="card" items={TAB_ITEMS} onChange={setActiveKey} />
      </>
   );
};

export default TestTabs;
