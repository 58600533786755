import { GeoJsonProperties } from 'geojson';
import { Expression, LinePaint } from 'mapbox-gl';
import { VECTOR_LAYER_ID } from 'power/types/enum';
import TL_VOLTAGE from 'power/types/enum/TL_VOLTAGE';
import { CustomLayerProps, ITransmissionLinesLayerProperties } from 'power/types/map';
import ITLFilter from 'power/types/map/ITLFilter';
import { utils } from 'power/utils';
import React, { useEffect, useMemo } from 'react';
import { Layer, MapRef, Source } from 'react-map-gl';

const { /* layerType, */ layerId, sourceUrl, sourceLayer, layerLayout, layerPaint, layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.ISONE_TRANSMISSON_LINES);

export const TL_DEFAULT_FILTER: ITLFilter = {
   active: false,
   voltages: [TL_VOLTAGE['0 - 68'], TL_VOLTAGE['69 - 114'], TL_VOLTAGE['115 - 274'], TL_VOLTAGE['275 - 344'], TL_VOLTAGE['345 - 499'], TL_VOLTAGE['500+']],
};

type Props = {
  map: MapRef;
  layerProps: CustomLayerProps<ITransmissionLinesLayerProperties, ITLFilter>;
  setPopup: (location: mapboxgl.LngLat, properties: GeoJsonProperties, layer: 'iq' | 'powerPlant' | 'tl') => void;
  beforeId?: string;
};

const IsoneTransmissionLinesLayer: React.FC<Props> = ({ map, layerProps, setPopup, beforeId }) => {
   const filter = useMemo(() => layerProps?.filter || TL_DEFAULT_FILTER, [layerProps]);

   /* const onChangeFilter = useCallback(
      (newFilter: ITLFilter) => {
         setStaticLayers(
            (prev) => prev && {
               ...prev,
               transmissionLines: {
                  ...prev.transmissionLines,
                  defaultFilter: newFilter,
               },
            },
         );
      },
      [setStaticLayers],
   ); */

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         const location = ev.lngLat;
         const properties = features.find((f) => f.layer.id === layerId)?.properties;
         if (!properties) return;
         setPopup(location, properties, 'tl');
      };

      if (map) {
         map.on('click', layerId, onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', layerId, onClickListener as any);
         }
      };
   }, [map, setPopup]);

   const layerFilter = useMemo(() => {
      const { active, voltages } = filter;
      if (!active) return ['any'];

      if (active) {
      // 1. method: voltage_class base filter
      // lf.push(['in', ['get', 'voltage_class'], ['literal', voltages]]);

         // 2. method: All voltage_class filter
         const voltageExp: Expression = ['any'];
         voltages.forEach((voltage) => {
            voltageExp.push(['==', ['get', 'voltage_class_range'], ['literal', voltage]]);
         });
         return voltageExp;
      }
   }, [filter]);

   return (
      <>
         {filter.active && (
            <Source id={layerId} type="vector" url={sourceUrl}>
               <Layer
                  id={layerId} type="line" source={layerId}
                  source-layer={sourceLayer} layout={layerLayout as any} paint={layerPaint as LinePaint}
                  minzoom={layerMinzoom} maxzoom={layerMaxzoom} beforeId={beforeId}
                  filter={layerFilter} interactive />
            </Source>
         )}
      </>
   );
};

export default IsoneTransmissionLinesLayer;
