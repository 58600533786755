import mapboxgl, { Marker } from 'mapbox-gl';
import { SelectLocation } from 'power/components/filter';
import { ILocationLookupMapBox } from 'power/types';
import { NODE_TYPE } from 'power/types/enum';
import React, { useCallback } from 'react';
import styles from './SearchControlContainer.module.scss';

type Props = {
  fetcher: (query: string) => Promise<ILocationLookupMapBox[]>;
  map?: mapboxgl.Map;
};
const SearchControlContainer: React.FC<Props> = ({ fetcher, map }) => {
   const [marker, setMarker] = React.useState<Marker | undefined>(undefined);

   /* const toggleStyle = useCallback(() => {
      if (map) {
         const nextStyleOption = styleOption === 'Default' ? 'Satellite' : 'Default';
         map.setStyle(MAPBOX.Styles[nextStyleOption]);
         setStyleOption(nextStyleOption);
      }
   }, [map, styleOption]);
 */
   const onSelectLocation = useCallback(
      (location: ILocationLookupMapBox) => {
         if (map) {
            if (marker) {
               marker.remove();
            }

            let color = '#757575'; // address, coordinate color
            if (location.nodeType === NODE_TYPE.Undefined) color = '#e74c3c'; // substation undefined color
            else if (location.nodeType === NODE_TYPE.DraftNode || location.nodeType === NODE_TYPE.IsoHub || location.nodeType === NODE_TYPE.IsoBus) {
               color = '#31bf6c'; //  hub, zone, interface, node color)
            }

            const newMarker = new Marker({ color }).setLngLat([location.lng, location.lat]).addTo(map);
            setMarker(newMarker);

            map.flyTo({ center: [location.lng, location.lat], zoom: 18 });
         }
      },
      [map, marker],
   );

   const onClear = useCallback(() => {
      if (marker) {
         marker.remove();
         setMarker(undefined);
      }
   }, [marker]);

   if (map === undefined) return null;

   return (
      <div className={styles.searchControlContainer}>
         <SelectLocation
            fetcher={fetcher} onSelectLocation={onSelectLocation} minWidth={416}
            onClear={onClear} />
      </div>
   );
};

export default SearchControlContainer;
