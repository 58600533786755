import { Expression } from 'mapbox-gl';
import { InfoTable } from 'power/components/base';
import { IMAGE_PATHS } from 'power/constants';
import addMapImages from 'power/map/helper/addMapImages';
import { GenerationPocketConstraintResponse } from 'power/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Popup, Source, useMap } from 'react-map-gl';
import { getConstraintsArrowLineGeojson, getConstraintsArrowPointGeojson } from './helper';
import { GENERATION_POCKETS_NODES_LAYER_ID } from './NodesLayer';

export const IMAGES: { name: string; url: string }[] = [{ name: 'arrow-right-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FILE('arrow-right.png') }];

// const LAYER_ID = 'GENERATION_POCKETS_CONSTRAINTS';
const LAYER_ID_ARROW_LINE = 'GENERATION_POCKETS_CONSTRAINTS_ARROW_LINE';
const LAYER_ID_ARROW_LINE_BUFFER = 'GENERATION_POCKETS_CONSTRAINTS_ARROW_LINE_BUFFER';
const LAYER_ID_ARROW_POINT = 'GENERATION_POCKETS_CONSTRAINTS_ARROW_POINT';

const MOUSE_CLICK_LAYER_IDS = [LAYER_ID_ARROW_LINE, LAYER_ID_ARROW_LINE_BUFFER, LAYER_ID_ARROW_POINT];
const MOUSE_MOVE_LAYER_IDS = [LAYER_ID_ARROW_LINE, LAYER_ID_ARROW_LINE_BUFFER, LAYER_ID_ARROW_POINT, GENERATION_POCKETS_NODES_LAYER_ID];

type Props = {
  mapId: string;
  constraints: GenerationPocketConstraintResponse;
  selectedPocket: number | null;
  show2035: boolean;
  show2030: boolean;
  showAllPockets: boolean;
};

const ConstraintsLayer: React.FC<Props> = ({ mapId, constraints, selectedPocket, show2030, show2035, showAllPockets }) => {
   const { [mapId]: map } = useMap();
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const [info, setInfo] = useState<mapboxgl.MapboxGeoJSONFeature | undefined>(undefined);
   const [clickedCoordinate, setClickedCoordinate] = useState<number[] | undefined>(undefined);

   const filter = useMemo(() => {
      const filterExpression: Expression = ['all'];

      const filterTargetYearExpression: Expression = ['any'];

      if (show2030) {
         filterTargetYearExpression.push(['==', ['get', 'target_year'], 2030]);
      }
      if (show2035) {
         filterTargetYearExpression.push(['==', ['get', 'target_year'], 2035]);
      }

      filterExpression.push(filterTargetYearExpression);

      if (showAllPockets || selectedPocket === null) return filterExpression;

      const filterPocketExpression: Expression = ['==', ['get', 'generation_pocket_id'], selectedPocket];
      filterExpression.push(filterPocketExpression);

      return filterExpression;
   }, [selectedPocket, show2030, show2035, showAllPockets]);

   // const geojson = useMemo(() => getConstraintsGeojson(constraints), [constraints]);
   const geojsonArrowLine = useMemo(() => getConstraintsArrowLineGeojson(constraints), [constraints]);
   const geojsonArrowPoint = useMemo(() => getConstraintsArrowPointGeojson(constraints), [constraints]);

   const loadImgaes = useCallback(() => {
      if (map === undefined) return;
      setLoadingImages(true);
      addMapImages(map, IMAGES).then(() => setLoadingImages(false));
   }, [map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);

   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         setInfo(undefined);
         setClickedCoordinate(undefined);

         setTimeout(() => {
            setInfo(features[0]);
            if (features[0].geometry.type === 'Point') setClickedCoordinate(features[0].geometry.coordinates);
            else setClickedCoordinate(ev.lngLat.toArray());
         }, 300);
      };

      if (map) {
         map.on('click', MOUSE_CLICK_LAYER_IDS, onClickListener);
      }

      return () => {
         if (map) {
            map.off('click', MOUSE_CLICK_LAYER_IDS, onClickListener);
         }
      };
   }, [map]);

   useEffect(() => {
      if (!map) return;

      const onMouseMoveListener = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
         // check all layer ids have been added
         const layerIds = map.getLayer(GENERATION_POCKETS_NODES_LAYER_ID) ? MOUSE_MOVE_LAYER_IDS : MOUSE_MOVE_LAYER_IDS.filter((id) => id !== GENERATION_POCKETS_NODES_LAYER_ID);

         const features = map.queryRenderedFeatures(e.point, { layers: layerIds });
         map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      };

      // set cursor to pointer when hovering over pocket
      map.on('mousemove', onMouseMoveListener);

      return () => {
         map.off('mousemove', onMouseMoveListener);
      };
   }, [map]);

   const infoKeyValues = useMemo(() => {
      if (!info?.properties) return undefined;
      const props: { key: string; value: any }[] = [];
      props.push({ key: 'Year', value: info.properties.target_year });
      /* props.push({ key: 'id', value: info.properties.id }); */
      props.push({ key: 'Name', value: info.properties.name });
      const substations = JSON.parse(info.properties.substations);
      props.push({ key: 'Substation From', value: substations.from_name });
      props.push({ key: 'Substation To', value: substations.to_name });

      const number_of_limiting_hours = JSON.parse(info.properties.number_of_limiting_hours);
      number_of_limiting_hours.forEach((limitingHour: any) => {
         props.push({ key: `Outlook ${limitingHour.study_year} \n ${limitingHour.case_type}`, value: limitingHour.value ?? '-' });
      });
      props.push({ key: 'Type', value: info.properties.type });
      // props.push({ key: 'Color', value: info.properties.color });

      return props;
   }, [info]);

   if (!map || loadingImages) return null;

   return (
      <>
         <Source id={LAYER_ID_ARROW_LINE} type="geojson" data={geojsonArrowLine}>
            <Layer
               id={LAYER_ID_ARROW_LINE} type="line" paint={{ 'line-color': '#3eb0e4', 'line-width': 2 }}
               filter={filter} />

            <Layer
               id={LAYER_ID_ARROW_LINE_BUFFER} type="line" paint={{ 'line-color': '#000000', 'line-opacity': 0, 'line-width': 12 }}
               filter={filter} />
         </Source>
         <Source id={LAYER_ID_ARROW_POINT} type="geojson" data={geojsonArrowPoint}>
            <Layer
               id={LAYER_ID_ARROW_POINT}
               type="symbol"
               layout={{
                  'icon-size': 0.02,
                  'icon-image': 'arrow-right-image-powerdev',
                  'icon-rotate': ['get', 'rotate'],
                  'icon-allow-overlap': true,
               }}
               filter={filter}
            />
         </Source>
         {/* <Source id={LAYER_ID} type="geojson" data={geojson}>
            <Layer
               id={LAYER_ID} type="line" paint={{ 'line-color': '#ff0000', 'line-width': 4, 'line-dasharray': [3, 3] }}
               filter={filter} />
         </Source> */}
         {info && infoKeyValues && clickedCoordinate && (
            <Popup
               longitude={clickedCoordinate[0]}
               latitude={clickedCoordinate[1]}
               closeButton
               closeOnMove={false}
               onClose={() => {
                  setInfo(undefined);
                  setClickedCoordinate(undefined);
               }}
               maxWidth="500px"
            >
               <div style={{ width: '100%' }}>
                  <InfoTable title="Constraint" info={infoKeyValues} width="100%" />
               </div>
            </Popup>
         )}
      </>
   );
};

export default ConstraintsLayer;
