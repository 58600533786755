import { BorderlessTableOutlined, DashboardOutlined, DeploymentUnitOutlined, HarmonyOSOutlined, HomeOutlined, JavaScriptOutlined, NotificationOutlined } from '@ant-design/icons';
import PATHS from 'power/PATHS';
import { Svg } from 'power/components/base/svg';
import { IMenuItemParams } from 'power/types';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import ENV from './ENV';

let MENU_ITEM_PARAMS: IMenuItemParams[] = [
   { label: 'Map', keyPath: PATHS.Home, icon: <HomeOutlined /> },
   { label: 'Node Analysis', keyPath: PATHS.NodeAnalysis, icon: <DeploymentUnitOutlined />, subscriptionPackage: SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS },
   { label: 'Transmission Capacity', keyPath: PATHS.TransmissionCapacity, icon: <BorderlessTableOutlined />, subscriptionPackage: SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY },
   { label: 'Interconnection Queue', keyPath: PATHS.IQ_List, icon: <Svg.Iq />, subscriptionPackage: SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE },
   { label: 'ICAP', keyPath: PATHS.ICAP, icon: <DashboardOutlined />, subscriptionPackage: SUBSCRIPTION_PACKAGE.ICAP },
   { label: 'Future', keyPath: PATHS.Future, icon: <Svg.Future />, subscriptionPackage: SUBSCRIPTION_PACKAGE.FUTURE_PRICE },
   { label: 'Renewable Generation Pockets', keyPath: PATHS.GenerationPockets, icon: <HarmonyOSOutlined />, subscriptionPackage: SUBSCRIPTION_PACKAGE.GENERATION_POCKET },
   { label: 'PowerNews AI', keyPath: PATHS.News, icon: <NotificationOutlined />, subscriptionPackage: SUBSCRIPTION_PACKAGE.NEWS },
];

if (ENV.DEVELOPMENT) {
   // MENU_ITEM_PARAMS.push({ label: 'Future', keyPath: PATHS.FutureMap, icon: <Svg.Future /> });
   // MENU_ITEM_PARAMS.push({ label: 'Future Heatmap', keyPath: PATHS.FutureHeatmap, icon: <DoubleRightOutlined /> });
   MENU_ITEM_PARAMS.push({ divider: true, keyPath: '', label: '' });
   MENU_ITEM_PARAMS.push({ label: 'Test', keyPath: PATHS.Test, icon: <JavaScriptOutlined /> });
   MENU_ITEM_PARAMS.push({ divider: true, keyPath: '', label: '' });
}

export default MENU_ITEM_PARAMS;
