export type GPLayers = {
  loading: boolean;
  defaultCollapsed: boolean;
  active: boolean;
  visible: {
    plant: boolean;
    largeLoad: boolean;
    constraint: boolean;
    tlUpgrade: boolean;
    nodes?: boolean;
  };
  filter: {
    plant: ('Solar' | 'Solar-cc' | 'Solar-lo' | 'Solar-hi' | 'Solar-hilo' | 'Wind' | 'Wind-cc' | 'Wind-lo' | 'Wind-hi' | 'Wind-hilo')[];
  };
  year2030: boolean;
  year2035: boolean;
};

export const INITIAL_GP_LAYERS: GPLayers = {
   loading: true,
   defaultCollapsed: true,
   active: true,
   visible: {
      plant: true,
      largeLoad: true,
      constraint: true,
      tlUpgrade: true,
   },
   filter: {
      plant: ['Solar', 'Solar-cc', 'Solar-lo', 'Solar-hi', 'Solar-hilo', 'Wind', 'Wind-cc', 'Wind-lo', 'Wind-hi', 'Wind-hilo'],
   },
   year2030: true,
   year2035: true,
};
