import PATHS from 'power/PATHS';
import { Navigate, Route, Routes as Router, useLocation } from 'react-router-dom';
import { PrivateRootLayout } from '../layout/PrivateRootLayout';
import { useAuth } from '../providers/AuthProvider';

import { SupportPage } from 'pages/private/Support';
import { ChangePassword } from 'pages/private/auth/ChangePassword';
import { LogoutPage } from 'pages/private/auth/Logout';
import { ActivateAccountPage } from 'pages/public/auth/ActivateAccount';
import { ResetPassword } from 'pages/public/auth/ResetPassword';
import { ForgotPassword } from '../pages/public/auth/ForgotPassword';
import { LoginPage } from '../pages/public/auth/Login';

import { FuturePage } from 'pages/private/FuturePage';
import { HistoricalHeatmapPage } from 'pages/private/HistoricalHeatmapPage';
import { ICAPPage } from 'pages/private/ICAPPage';
import { IQListPage } from 'pages/private/IQListPage';
import { IndexPage } from 'pages/private/Index';
import { NodeAnalysisPage } from 'pages/private/NodeAnalysisPage';
import { TransmissionCapacityPage } from 'pages/private/TransmissionCapacityPage';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import { useIso } from 'providers/useIso';
import { GenerationPocketsPage } from '../pages/private/GenerationPocketsPage';
import { MapPage } from '../pages/private/MapPage';
import { MobilePage } from '../pages/private/MobilePage';
import { NewsListPage } from '../pages/private/NewsListPage';
import { PrivateNotFoundPage } from '../pages/private/PrivateNotFoundPage';
import { TestPage } from '../pages/private/TestPage';
import { ModuleRoute } from './ModuleRoute';

const PrivateRoutes = () => {
   const { authenticated } = useAuth();
   const location = useLocation();

   if (!authenticated) return <Navigate to={PATHS.Auth_Login} replace state={{ from: location.pathname + location.search }} />;

   if (location.state && location.state.from) return <Navigate replace to={location.state.from} />;

   return <PrivateRootLayout />;
};

const IsoPrivateRoutes = () => {
   const { iso } = useIso();
   const { authenticated, isos } = useAuth();
   const location = useLocation();

   if (!authenticated) return <Navigate to={PATHS.Auth_Login} replace state={{ from: location.pathname + location.search }} />;

   if (iso === undefined) return <PrivateNotFoundPage />;

   if (isos.length === 0) return null;

   if (!isos.includes(iso)) return <PrivateNotFoundPage />;

   return <PrivateRootLayout />;
};

export const AppRoutes = () => (
   <Router>
      <Route path={PATHS.Auth_Login} element={<LoginPage />} />
      <Route path={PATHS.Auth_ForgetPassword} element={<ForgotPassword />} />
      <Route path={`${PATHS.Auth_ResetPassword}/:key`} element={<ResetPassword />} />
      <Route path={`${PATHS.Auth_ActivateAccount}/:key`} element={<ActivateAccountPage />} />
      <Route path={PATHS.Auth_Logout} element={<LogoutPage />} />

      <Route element={<PrivateRoutes />}>
         <Route path={PATHS.Index} element={<IndexPage />} />

         <Route path={PATHS.HistoricalHeatmap} element={<HistoricalHeatmapPage />} />
         <Route path={PATHS.Mobile} element={<MobilePage />} />
         <Route path="*" element={<PrivateNotFoundPage />} />
      </Route>

      <Route path=":iso">
         <Route element={<IsoPrivateRoutes />}>
            <Route index element={<MapPage />} />
            <Route path={PATHS.MapPage} element={<MapPage />} />

            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY]} />}>
               <Route path={PATHS.TransmissionCapacity} element={<TransmissionCapacityPage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS]} />}>
               <Route path={PATHS.NodeAnalysis} element={<NodeAnalysisPage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE]} />}>
               <Route path={PATHS.IQ_List} element={<IQListPage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.ICAP, SUBSCRIPTION_PACKAGE.ICAP_FORECAST]} all={false} />}>
               <Route path={PATHS.ICAP} element={<ICAPPage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.FUTURE_PRICE]} />}>
               <Route path={PATHS.Future} element={<FuturePage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.GENERATION_POCKET]} />}>
               <Route path={PATHS.GenerationPockets} element={<GenerationPocketsPage />} />
            </Route>
            <Route element={<ModuleRoute subscriptionPackages={[SUBSCRIPTION_PACKAGE.NEWS]} />}>
               <Route path={PATHS.News} element={<NewsListPage />} />
            </Route>

            <Route path={PATHS.Auth_ChangePassword} element={<ChangePassword />} />
            <Route path={PATHS.Support} element={<SupportPage />} />
            <Route path={PATHS.Test} element={<TestPage />} />
         </Route>
      </Route>
   </Router>
);
