import { ApiClients } from 'ApiClients';
import { useEffect, useState } from 'react';

export const useICAPFutureYears = () => {
   const [loading, setLoading] = useState<boolean>(true);
   const [years, setYears] = useState<number[]>([]);

   useEffect(() => {
      const handleData = () => {
         ApiClients.getInstance()
            .icapFutureYears()
            .then((res) => {
               setYears(res);
            })
            .finally(() => {
               setLoading(false);
            });
      };

      handleData();
   }, []);

   return {
      loading,
      years,
   };
};
