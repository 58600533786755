import { Header } from 'antd/es/layout/layout';
// import { ENV } from 'power/constants';
import { USER_TYPE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import classes from './HeaderPrimary.module.scss';

export const HeaderPrimary = ({ children }: { children: React.ReactNode }) => {
   const { isUserType } = useAuth();

   const label = useMemo(() => {
      if (isUserType(USER_TYPE.ZENOBE)) {
         return 'ZENOBE';
      }

      if (isUserType(USER_TYPE.CVE_NA)) {
         return 'CVE North America';
      }

      if (isUserType(USER_TYPE.INTERNAL)) {
         return 'PowerDev Internal';
      }

      return undefined;
   }, [isUserType]);

   return (
      <Header className={classes.headerPrimary}>
         <>
            {children}
            {label && (
               <div className={classes.logoVersion}>
                  {label === 'ZENOBE' && (
                     <>
                        <img src="/img/zenobe-logo-small.png" alt="ZENOBE" style={{ height: 14 }} />
                        <span className={classes.zenobe}>SPECIFIC VERSION</span>
                     </>
                  )}
                  {label === 'CVE North America' && (
                     <>
                        <img src="/img/cve_logo.svg" alt="ZENOBE" /* style={{ height: '14px' }} */ />
                        <span className={classes.cve}>SPECIFIC VERSION</span>
                     </>
                  )}
                  {label === 'PowerDev Internal' && (
                     <>
                        <span className={classes.label}>PowerDev</span> <span className={classes.powerdev}>Internal Version</span>
                     </>
                  )}
               </div>
            )}
         </>
      </Header>
   );
};
