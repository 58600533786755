/* eslint-disable max-len */
import PageContainer from 'components/ui/PageContainer';
import PowerPage from 'power/components/base/PowerPage/PowerPage';
import { API_ROUTES } from 'power/constants';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { useIso } from 'providers/useIso';
import { useState } from 'react';
import Container from './Container';
import QueryBar from './QueryBar/QueryBar';

export const NodeAnalysis = () => {
   const { iso } = useIso();
   const [loading, setLoading] = useState<boolean>(true);
   const [query, setQuery] = useState<Partial<INodeInfoRequest> | undefined>({ provider: iso });
   const [data, setData] = useState<INodeInfoResponse | undefined>();

   return (
      <PageContainer
         status={loading ? 'loading' : 'success'} statusCode={200} filterBarRowCount={1}
         pageHeader={(
            <PowerPage
               loading={loading} setLoading={setLoading} query={query}
               setQuery={setQuery} setPageData={setData} apiRoute={API_ROUTES.NODE.PageDetail}
               QueryBar={QueryBar} />
         )}>
         {query !== undefined && data !== undefined && <Container query={query} data={data} />}
      </PageContainer>
   );
};
