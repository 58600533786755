import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { MARKET_TYPE } from 'power/types/enum';
import { FC, memo } from 'react';
import InfoBoxes from './components/InfoBoxes';
import MonthlyArbitrageTimeSeriesChart from './components/MonthlyTimeSeriesChart';
import YearlyArbitrageTimeSeriesChart from './components/YearlyTimeSeriesChart';

type Props = {
   query: Partial<INodeInfoRequest>;
   data: INodeInfoResponse;
};

const ArbitrageTab: FC<Props> = ({ query, data }) => (
   <PowerRow gutter={[16, 16]}>
      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <InfoBoxes nodeId={data.node.id} />
      </PowerCol>

      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <MonthlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.DayAhead} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.DayAhead} hour={4} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.DayAhead} hour={3} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.DayAhead} hour={2} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.DayAhead} hour={1} />
      </PowerCol>

      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <MonthlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.Realtime} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.Realtime} hour={4} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.Realtime} hour={3} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.Realtime} hour={2} />
      </PowerCol>

      <PowerCol
         xs={24} sm={12} md={6}
         lg={6}>
         <YearlyArbitrageTimeSeriesChart nodeId={data.node.id} marketType={MARKET_TYPE.Realtime} hour={1} />
      </PowerCol>
   </PowerRow>
);

export default memo(ArbitrageTab);
