import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Form, Input, Space } from 'antd';
import { REGEXES } from 'power/constants';
import { FC } from 'react';

type Props = {
  loading: boolean;
  onActivateAccount: (firstName: string, lastName: string, password: string) => Promise<void>;
  errorMessage?: string;
};

const ActivateAccountForm: FC<Props> = ({ loading, onActivateAccount, errorMessage }) => {
   const [form] = Form.useForm();

   return (
      <>
         {errorMessage && <Alert message={errorMessage} type="error" style={{ marginBottom: '1rem' }} />}
         <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => {
               onActivateAccount(values.firstName, values.lastName, values.password);
            }}
         ><Flex>
               <Space size="large" style={{ width: '100%' }}>
                  <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please input first name!' }]}>
                     <Input
                        size="large" placeholder="Enter first name" prefix={<UserOutlined />}
                        disabled={loading} />
                  </Form.Item>
                  <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please input last name!' }]}>
                     <Input
                        size="large" placeholder="Enter last name" prefix={<UserOutlined />}
                        disabled={loading} />
                  </Form.Item>
               </Space>
            </Flex>
            <Form.Item
               label="Enter new password"
               name="password"
               rules={[
                  { required: true, message: 'Please input your new password!' },
                  { min: 6, message: 'Password must be at least 6 characters long' },
                  { max: 24, message: 'Password must be at most 24 characters long' },
                  { pattern: REGEXES.PASSWORD, message: 'Password must contain at least one letter and one number' },
               ]}
            >
               <Input.Password
                  size="large" placeholder="Enter new password" type="password"
                  prefix={<KeyOutlined />} disabled={loading} />
            </Form.Item>
            <Form.Item
               style={{ marginTop: '2rem' }}
               label="Confirm new password"
               name="password2"
               rules={[
                  { required: true, message: 'Please confirm your new password!' },
                  { min: 6, message: 'Password must be at least 6 characters long' },
                  { max: 24, message: 'Password must be at most 24 characters long' },
                  { pattern: REGEXES.PASSWORD, message: 'Password must contain at least one letter and one number' },
                  ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                           return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                     },
                  }),
               ]}
            >
               <Input.Password
                  size="large" placeholder="Confirm new password" type="password"
                  prefix={<KeyOutlined />} disabled={loading} />
            </Form.Item>
            <Form.Item>
               <Button
                  style={{ margin: '1rem 0 2rem' }} block type="primary"
                  size="large" htmlType="submit" loading={loading}>
            Activate Account
               </Button>
            </Form.Item>
         </Form>
      </>
   );
};

export default ActivateAccountForm;
