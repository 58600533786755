import { CloseOutlined, LineChartOutlined, TableOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import collapsePng from './collapse.png';
import styles from './ShowHide.module.scss';

type Props = {
  mapId: string;
  leftSiderOpen: boolean;
  setLeftSiderOpen: (open: boolean) => void;
  tableViewOpen: boolean;
  setTableViewOpen: (open: boolean) => void;
  chartViewOpen: boolean;
   setChartViewOpen: (open: boolean) => void;
};

const ShowHide: React.FC<Props> = ({ mapId, leftSiderOpen, setLeftSiderOpen, tableViewOpen, setTableViewOpen, chartViewOpen, setChartViewOpen }) => {
   const { [mapId]: map } = useMap();

   const popupOpen = tableViewOpen || chartViewOpen;
   const setPopupOpen = (open: boolean) => {
      setTableViewOpen(open);
      setChartViewOpen(open);
   }

   useEffect(() => {
      if (map) {
         map.resize();
      }
   }, [map, leftSiderOpen]);

   return (
      <>
         <div className={styles.collapse} /* style={{ position: 'absolute', left: 10, top: 6, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 98, width: 36, height: 36, padding: 6, cursor: 'pointer' }} */>
            <div className={styles.closeBtn}>
               <img
                  src={collapsePng} alt="collapse" style={{ width: 48, height: 48, transform: leftSiderOpen ? 'scalex(-1)' : 'scalex(1)' }}
                  onClick={() => setLeftSiderOpen(!leftSiderOpen)} />
            </div>
         </div>

         {popupOpen ? (
            <div style={{ position: 'absolute', right: 10, top: 6, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 36, height: 36, padding: 6, cursor: 'pointer' }} onClick={() => setPopupOpen(false)}>
               <CloseOutlined style={{ fontSize: 24 }} />
            </div>
         ) : (
            <>
               <div style={{ position: 'absolute', right: 7, bottom: 7, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 36, height: 36, padding: 6, cursor: 'pointer' }} onClick={() => setTableViewOpen(true)}>
                  <TableOutlined style={{ fontSize: 24 }} />
               </div>
               <div style={{ position: 'absolute', right: 50, bottom: 7, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 36, height: 36, padding: 6, cursor: 'pointer' }} onClick={() => setChartViewOpen(true)}>
                  <LineChartOutlined style={{ fontSize: 24 }} />
               </div>
            </>
         )}
      </>
   );
};

export default ShowHide;
