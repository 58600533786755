import { Line } from '@ant-design/plots';
import { Checkbox, Table } from 'antd';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import { ColorSets } from 'constants/NewColors';
import { useContent } from 'content';
import { useBasisRiskOverviewTimeSeriesData } from 'modules/NodeAnalysis/Container/Tabs/basis-risk/hooks';
import useAntdChartConfig from 'power/hooks/useAntdChartConfig';
import { MARKET_TYPE, PRICE_COMPONENT, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';
import { normalizeNumber } from 'utils/math';

type Props = {
  nodeId: number;
  hubId: number;
};

const BasisRiskNodeHubTimeSeries: FC<Props> = ({ nodeId, hubId }) => {
   const { t } = useContent();

   const [marketTypes, setMarketTypes] = useState<MARKET_TYPE[]>([MARKET_TYPE.Realtime]);

   // const [range, setRange] = useState<number[]>(null as any);

   const chartConfig = useAntdChartConfig('line', 'Monthly', '$/MWh');

   const { isFetching, data, error, refetch } = useBasisRiskOverviewTimeSeriesData({
      nodes: [nodeId],
      dayAheadMarket: marketTypes.includes(MARKET_TYPE.DayAhead) ? 1 : 0,
      realtimeMarket: marketTypes.includes(MARKET_TYPE.Realtime) ? 1 : 0,
      hubId,
      component: PRICE_COMPONENT.NO,
   });

   /* useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]); */

   return (
      <CardPanel
         title={t('basisRiskOverview.nodeHubTimeSeries')}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : (data?.res?.result?.data?.length ?? 0) < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         downloadURL={data?.res?.downloadUrl}
         refreshClick={refetch}
         info={t('basisRiskOverview.basisRiskTimeSeriesChartInfo')}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {/* {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data.range[0]}
                              max={data.range[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )} */}

                  <Checkbox.Group
                     value={marketTypes}
                     options={[
                        {
                           label: t('base.da'),
                           value: MARKET_TYPE.DayAhead,
                        },
                        {
                           label: t('base.rt'),
                           value: MARKET_TYPE.Realtime,
                        },
                     ]}
                     onChange={(d) => {
                        if (d.length > 0) setMarketTypes(d);
                     }}
                  />
               </div>

               {viewMode === 'chart' && (
                  <Line
                     {...chartConfig}
                     data={data?.res?.result?.data ?? []}
                     xField="x"
                     yField="y"
                     seriesField="group"
                     xAxis={{
                        ...chartConfig.xAxis,
                        tickCount: 8,
                        label: {
                           formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
                        },
                     }}
                     yAxis={{
                        ...chartConfig.yAxis,
                        // min: range?.[0],
                        // max: range?.[1],
                     }}
                     tooltip={{
                        shared: true,
                        title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.monthly),
                        formatter: (d) => ({
                           name: d.group,
                           value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                        }),
                     }}
                     color={(d) => {
                        const colorIndex = data?.allDataGroups?.indexOf(String(d.group).replace('-DA', '').replace('-RT', ''));

                        const color = d.group.includes('-DA') ? ColorSets.main[colorIndex as any] : ColorSets.dark[colorIndex as any];

                        return color;
                     }}
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.date'),
                           dataIndex: 'date',
                           key: 'date',
                        },
                        {
                           title: t('base.marketType'),
                           dataIndex: 'marketType',
                           key: 'marketType',
                        },
                        {
                           title: 'Component',
                           dataIndex: 'component',
                           key: 'component',
                        },
                        {
                           title: 'Basis Risk',
                           dataIndex: 'Basis',
                           key: 'Basis',
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.tableData ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default BasisRiskNodeHubTimeSeries;
