/* eslint-disable max-len */
import { Line } from '@ant-design/plots';
import { Radio } from 'antd';
import CardPanel from 'components/ui/CardPanel';
import { ITxBxPerformanceTimeSeriesRequest } from 'power/types';
import { MARKET_TYPE, MOVING_AVERAGE_TYPE, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { utils } from 'power/utils';
import { useEffect, useMemo, useState } from 'react';
import usePerformanceTimeSeries from '../usePerformanceTimeSeries';
import useMovingAverageTimeSeries from './useMovingAverageTimeSeries';

const COLORS_DA = ['#ffcf89', '#000'];
const COLORS_RT = ['#6394f9', '#000'];

const PerformanceTimeSeriesChart = ({ filter, marketType, marketTypeFilter = false }: { filter: Partial<ITxBxPerformanceTimeSeriesRequest>; marketType: MARKET_TYPE, marketTypeFilter?: boolean }) => {
   const { loading: loadingPerformance, data: dataPerformance, fetch: fetchPerformance } = usePerformanceTimeSeries();
   const { loading: loadingMoving, data: dataMoving, fetch: fetchMoving } = useMovingAverageTimeSeries();

   const [tbxConponent, setTbxComponent] = useState<TXBX_COMPONENT>(filter.component ?? TXBX_COMPONENT.Tb4);
   const [marketTypeLocal, setMarketTypeLocal] = useState<MARKET_TYPE>(marketType);
   
   useEffect(() => {
      fetchPerformance({ ...filter, component: tbxConponent, marketType: marketTypeLocal });
      fetchMoving({ ...filter, component: tbxConponent, movingAverage: MOVING_AVERAGE_TYPE.MovingAvg100Day, marketType: marketTypeLocal });
   }, [fetchPerformance, fetchMoving, filter, marketTypeLocal, tbxConponent]);

   const data = useMemo(() => {
      if (dataPerformance?.result?.data && dataMoving?.result?.data) {
         return [...dataPerformance.result.data, ...dataMoving.result.data];
      }
      return [];
   }, [dataPerformance, dataMoving]);

   const infoContent = marketTypeLocal === MARKET_TYPE.DayAhead ?
      'Day-Ahead TB4 performance is calculated by subtracting the average of the lowest four values from the average of the highest four values and then dividing this difference by the number of hours to obtain the Hub LMP value. This value is then subtracted from the calculated Nodal value, which is obtained by dividing the difference between the averages of the highest and lowest four values by the number of hours. The result is then divided by the same Hub LMP value. A positive value indicates that the Nodal LMP is higher than the Hub LMP.' :
      'Real-Time TB4 performance is calculated by subtracting the average of the lowest four values from the average of the highest four values and then dividing this difference by the number of hours to obtain the Hub LMP value. This value is then subtracted from the calculated Nodal value, which is obtained by dividing the difference between the averages of the highest and lowest four values by the number of hours. The result is then divided by the same Hub LMP value. A positive value indicates that the Nodal LMP is higher than the Hub LMP.';
      
   return (
      <CardPanel
         title={`${marketTypeLocal === MARKET_TYPE.DayAhead ? 'DA' : 'RT'} ${TXBX_COMPONENT[tbxConponent].toUpperCase()} Nodal Performance vs Hub`}
         defaultViewMode="chart"
         status={loadingPerformance || loadingMoving ? 'loading' : 'success'}
         fullScreen
         /* downloadURL={dataMoving?.downloadUrl} */
         refreshClick={() => {
            fetchPerformance({ ...filter, component: tbxConponent, marketType: marketTypeLocal }, true);
            fetchMoving({ ...filter, component: tbxConponent, movingAverage: MOVING_AVERAGE_TYPE.MovingAvg100Day, marketType: marketTypeLocal }, true);
            // fetchPerformance(filter, true);
            // fetchMoving(filter, true);
         }}
         info={infoContent}
      >
         {() => (
            <>
               <div className="card-panel-filter-bar">
                  {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}                  
                  {marketTypeFilter && (
                     <Radio.Group value={marketTypeLocal} onChange={(e) => setMarketTypeLocal(e.target.value)} style={{ marginRight: '3rem'}}>
                        <Radio value={MARKET_TYPE.DayAhead}>DA</Radio>
                        <Radio value={MARKET_TYPE.Realtime}>RT</Radio>
                     </Radio.Group>
                  )}

                  <Radio.Group value={tbxConponent} onChange={(e) => setTbxComponent(e.target.value)}>
                     <Radio value={TXBX_COMPONENT.Tb1}>TB1</Radio>
                     <Radio value={TXBX_COMPONENT.Tb2}>TB2</Radio>
                     <Radio value={TXBX_COMPONENT.Tb3}>TB3</Radio>
                     <Radio value={TXBX_COMPONENT.Tb4}>TB4</Radio>
                  </Radio.Group>
                  {/* </div> */}
               </div>
               <div style={{ margin: '-10px 16px', color: '#808080' }}>%</div>
               <Line
                  // loading={loadingPerformance || loadingMoving}
                  className="antd-chart"
                  data={data}
                  xField="x"
                  yField="y"
                  seriesField="group"
                  appendPadding={8}
                  syncViewPadding
                  lineStyle={(d: any) => {
                     if (d.group.startsWith('Tb')) return { lineWidth: 2 };
                     return { lineWidth: 2, lineDash: [4, 4] };
                  }}
                  color={marketTypeLocal === MARKET_TYPE.DayAhead ? COLORS_DA : COLORS_RT}
                  xAxis={{
                     label: {
                        formatter: (x) => utils.math.formatDate(x, TIME_GRANULARITY.daily),
                     },
                  }}
                  tooltip={{
                     title: (d) => utils.math.formatDate(d, TIME_GRANULARITY.daily),
                     formatter: (d) => ({ name: d.group, value: d.y }),
                  }}
                  legend={{
                     position: 'top',
                     padding: [0, 0, 10, 64],
                     marker: (group: string) => (group.startsWith('Tb') ? {} : { symbol: 'hyphen', style: { lineDash: [4, 4] } }),
                  }}
                  yAxis={{
                     min: -100,
                     max: 100,
                  }}
               />
            </>
         )}
      </CardPanel>
   );
};

export default PerformanceTimeSeriesChart;
