import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Select } from 'antd';
import { PowerCol } from 'components/ui';
import { NYISO_HUBS } from 'modules/Historical/NYISO_HUBS';
import { PowerRow } from 'power/components/base/grid';
import { HistoricalHeatmapRequest } from 'power/types';
import { MARKET_TYPE, NYISO_ZONE } from 'power/types/enum';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  query: Omit<HistoricalHeatmapRequest, 'year'>;
  onSubmit: (newQuery: Omit<HistoricalHeatmapRequest, 'year'>) => void;
};

const HistoricalHeatmapFilter: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formQuery, setFormQuery] = useState<Omit<HistoricalHeatmapRequest, 'year'>>(query);
  
   const onChangeMarketType = useCallback((value: MARKET_TYPE) => {
      setFormQuery((prev) => ({ ...prev, marketType: value }));
   }, []);

   const onChangeZone = useCallback((nodeId: number) => {
      setFormQuery((prev) => ({ ...prev, nodeId }));
   }, []);

   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Market Type:</span>
               <Select
                  variant="borderless" placeholder="Select market type" value={formQuery.marketType}
                  disabled={loading} onChange={onChangeMarketType}>
                  <Select.Option value={MARKET_TYPE.DayAhead}>Day-Ahead</Select.Option>
                  <Select.Option value={MARKET_TYPE.Realtime}>Real Time</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={4} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Zone:</span>
               <Select
                  variant="borderless"
                  placeholder="Select zone"
                  value={formQuery.nodeId}
                  disabled={loading}
                  onChange={onChangeZone}
                  options={ NYISO_HUBS.map((hub) => ({ label: `${ NYISO_ZONE[hub.zone]} - ${hub.label}`, value: hub.id })) }
               ></Select>
            </Flex>
         </PowerCol>

         
         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default HistoricalHeatmapFilter;

