const API_ROUTES_V2 = {
   AUTH: {
      Login: 'token',
      ChangePassword: '/me/change_password',
      ForgotPassword: '/users/forgot_password',
      ResetPassword: '/users/reset_password',
      ActivateAccount: '/users/activate_account',
      Logout: '/me/logout',
      Me: '/me',
   },
   ICAP: {
      Strip: 'icap/strip',
      StripDetail: 'icap/strip/detail',
      Spot: 'icap/spot',
      SpotDetail: 'icap/spot/detail',
      Monthly: 'icap/monthly',
      MonthlyDetail: 'icap/monthly/detail',
      CapacityParameters: '/icap/capacity_parameters',
      TimeseriesStrip: '/icap/strip/timeseries',
      TimeseriesSpot: '/icap/spot/timeseries',
      TimeseriesMonthly: '/icap/monthly/timeseries',
      Future: '/icap_forecast',
      FutureTimeseries: '/icap_forecast/timeseries',
      FutureYears: '/icap_forecast/years',
   },
   FUTURE: {
      Price: 'future/price',
      Heatmap: 'future/heatmap',
      Timeseries: '/future/timeseries',
      Tbx: '/future/tbx',
      TBxTimeSeries: '/future/tbx/timeseries',
   },
   HEADROOM_CAPACITY: {
      Map: 'headroom_capacity/data',
      Substations: 'headroom_capacity/substations',
      SubstationsList: 'headroom_capacity/substations/list',
      SubstationsSearch: '/headroom_capacity/substation/search',
      Branch: 'headroom_capacity/branches',
      ConstraintsBySubstation: 'headroom_capacity/substation_constraints',
      ConstraintsByBranch: 'headroom_capacity/branch_constraints',
      Branches: '/headroom_capacity/branches/by_substation',
      StaticLayers: '/headroom_capacity/map/layers',
   },
   NEWS: {
      List: 'news',
   },
   GENERATION_POCKET: {
      Map: 'generation_pocket',
      Info: 'generation_pocket/info',
      ConstraintList: 'generation_pocket/constraint',
      TransmissionLineList: 'generation_pocket/transmission_upgrades',
      PlantList: 'generation_pocket/plant',
      LargeLoad: 'generation_pocket/large_load',
      Summary: 'generation_pocket/info/summary',
      BindingContingency: 'generation_pocket/binding_contingency',
      Description: 'generation_pocket/description',
      DescriptionRegion: 'generation_pocket/region/description',
      AverageCurtailment: 'generation_pocket/info/average_curtailment',
   },
};

export default API_ROUTES_V2;
