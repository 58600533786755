import mapboxgl from 'mapbox-gl';
import React, { PropsWithChildren } from 'react';
import collapsePng from './collapse.png';
import styles from './SiderRightControlContainer.module.scss';

type Props = {
  map?: mapboxgl.Map;
  defaultOpen?: boolean;
  width?: string | number;
};

const SiderRightControlContainer: React.FC<PropsWithChildren<Props>> = ({ map, defaultOpen = true, width = 400, children }) => {
   const [open, setOpen] = React.useState<boolean>(defaultOpen);

   if (map === undefined) return null;

   // console.log(map.getContainer().parentElement, map.getContainer().parentElement?.style.height);
   // const parentHeight = map.getContainer().parentElement?.style.height;
   // const maxHeight = parentHeight ? `calc(${parentHeight} - 100px)` : 'calc(100svh - 100px)';
   const maxHeight = 'calc(100svh - 100px)';

   return (
      <>
         <div className={styles.collapse} style={{right: open ? Number(width) - 10 : -10}}>
            <div className={styles.closeBtn}>
               <img
                  src={collapsePng} alt="collapse" style={{width: 48, height: 48, transform: open ? 'scalex(1)' : 'scalex(-1)' }}
                  onClick={() => setOpen((prev) => !prev)} />
            </div>
         </div>
      
         <div className={`${styles.siderControlContainer} ${open ? styles.isOpen : styles.isClose}`} style={{ maxHeight, width: open ? width : 0 }}>
            <div className={styles.content}>{children}</div>
         </div>
      </>
   );
};

export default SiderRightControlContainer;
