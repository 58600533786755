import { CloseOutlined, LineChartOutlined } from '@ant-design/icons';
import PowerMap from 'power/map';
import { IICAPFutureRequest } from 'power/types';
import { useCallback, useState } from 'react';
import ChartView from './ChartView';
import ICAPFutureMapFilter from './FilterBar';
import classes from './index.module.scss';
import ICAPFutureLayer from './layers/ICAPFutureLayer';
import ICAPZoneLayer from './layers/ICAPZoneLayer';
import { useICAPFutureData } from './layers/useICAPFutureData';

const MAP_ID = 'icap-future-map-v2';

const ICAPFuturelMap = () => {
   const { loading, query, setQuery, data, years } = useICAPFutureData();

   const [chartViewOpen, setChartViewOpen] = useState(false);

   const onSubmit = useCallback(
      (query: IICAPFutureRequest) => {
         setQuery(query);
      },
      [setQuery],
   );

   return (
      <div className={classes.fullContainer}>
         <div className={classes.filterBar}>
            <ICAPFutureMapFilter
               loading={loading} years={years} query={query}
               onSubmit={onSubmit} />
         </div>
         <div className={classes.container}>
            <PowerMap
               id={MAP_ID}
               /* customMapProps={{ maxBounds: [-82.02867082714488, 40.3754382777056, -69.65441258121662, 45.12977354909813] as [number, number, number, number], minZoom: 6.7, maxZoom: 6.7 }} */
               customMapProps={{ maxBounds: [-81.64, 40.22, -68.61, 45.225] as [number, number, number, number], minZoom: 5.99, maxZoom: 5.99, maxPitch: 0, bearing: 0 }}
            >
               <ICAPZoneLayer mapId={MAP_ID} />
               <ICAPFutureLayer mapId={MAP_ID} query={query} data={data} />
            </PowerMap>
            {chartViewOpen && <ChartView query={query} />}
            {chartViewOpen ? (
               <div style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }} onClick={() => setChartViewOpen(false)}>
                  <CloseOutlined style={{ fontSize: 32 }} />
               </div>
            ) : (
               <>
                  <div style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }} onClick={() => setChartViewOpen(true)}>
                     <LineChartOutlined style={{ fontSize: 32 }} />
                  </div>
               </>
            )}
         </div>
      </div>
   );
};

export default ICAPFuturelMap;
