import { DATA_PROVIDER } from 'power/types/enum';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useIso = () => {
   const { iso } = useParams<{iso: string | undefined }>();
   const navigate  = useNavigate();

   const setIso = useCallback((newIso: DATA_PROVIDER) => {
      const currentIso = iso ? iso.toUpperCase() as any as DATA_PROVIDER : DATA_PROVIDER.Undefined;
      if(currentIso !== newIso){
         localStorage.setItem('iso', newIso.toString());
         // just first path replace with  `/${DATA_PROVIDER[newIso].toLowerCase()}`;
         navigate(`/${DATA_PROVIDER[newIso].toLowerCase()}${window.location.pathname.replace(`/${iso}`, '')}`);
         // window.location.pathname = window.location.pathname.replace(`/${iso}`, `/${DATA_PROVIDER[newIso].toLowerCase()}`);
      }
   }, [iso, navigate]);

   return {iso: iso ? DATA_PROVIDER[iso.toUpperCase() as any] as any as DATA_PROVIDER : DATA_PROVIDER.Undefined, setIso};
};
