import { GeoJsonProperties } from 'geojson';
import addMapImages from 'power/map/helper/addMapImages';
import { CustomLayerProps, IBaseFilter, IIQLayerProperties } from 'power/types/map';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MapRef } from 'react-map-gl';
import SymbolLayer from './SymbolLayer';
import { images } from './images';
import useStaticLayers from './useIqLayer';

const INTERCONNECTION_QUEUE_LAYER = { layerId: 'Interconnection Queue', image: 'iq-image-powerdev-2' };

export const STATIC_DEFAULT_FILTER: IBaseFilter = {
   active: false,
};

type Props = {
  map: MapRef;
  iq?: CustomLayerProps<IIQLayerProperties, IBaseFilter>;
  setPopup: (location: mapboxgl.LngLat, properties: GeoJsonProperties, layer: 'iq' | 'powerPlant' | 'tl') => void;
  beforeId?: string;
};

const IqLayers: FC<Props> = ({ map, iq, setPopup }) => {
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const filterIq = useMemo(() => iq?.filter || STATIC_DEFAULT_FILTER, [iq]);
   const { iqLayer } = useStaticLayers(true); // filterIq.active);

   const filterActive = filterIq.active;

   const loadImgaes = useCallback(() => {
      if(map === undefined) return;
      setLoadingImages(true);
      addMapImages(map, images).then(() => setLoadingImages(false));
   }, [map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);
   
   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;
         const feature = features[0];
         // console.log('click', feature);
         setPopup(ev.lngLat, feature.properties, 'iq');
      };

      if (map) {
         map.on('click', [INTERCONNECTION_QUEUE_LAYER.layerId], onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', [INTERCONNECTION_QUEUE_LAYER.layerId], onClickListener as any);
         }
      };
   }, [map, setPopup]);

   if (loadingImages || iqLayer === undefined || filterActive === false) return null;

   return (
      <>
         {filterIq.active && iqLayer && (
            <SymbolLayer
               layerId={INTERCONNECTION_QUEUE_LAYER.layerId}
               iconImage={INTERCONNECTION_QUEUE_LAYER.image}
               geojson={iqLayer}
               /* iconOffset={[0, -200]} */
            />
         )}
      </>
   );
};

export default IqLayers;
