import { Expression } from 'mapbox-gl';
import { IMAGE_PATHS } from 'power/constants';
import addMapImages from 'power/map/helper/addMapImages';
import { GenerationPocketLargeLoad } from 'power/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { getLargeLoadsGeojson } from './helper';

export const IMAGES: { name: string; url: string }[] = [{ name: 'large-load-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FILE('large-load.png') }];

const LAYER_ID = 'GENERATION_POCKETS_LARGE_LOAD';

type Props = {
  mapId: string;
  largeLoads: GenerationPocketLargeLoad[];
  selectedPocket: number | null;
  show2035: boolean;
  show2030: boolean;
  showAllPockets: boolean;
};

const LargeLoadLayer: React.FC<Props> = ({ mapId, largeLoads, selectedPocket, show2030, show2035, showAllPockets }) => {
   const { [mapId]: map } = useMap();
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const filter = useMemo(() => {
      const filterExpression: Expression = ['all'];

      const filterTargetYearExpression: Expression = ['any'];

      if (show2030) {
         filterTargetYearExpression.push(['==', ['get', 'target_year'], 2030]);
      }
      if (show2035) {
         filterTargetYearExpression.push(['==', ['get', 'target_year'], 2035]);
      }

      filterExpression.push(filterTargetYearExpression);

      if (showAllPockets || selectedPocket === null) return filterExpression;

      const filterPocketExpression: Expression = ['==', ['get', 'generation_pocket_id'], selectedPocket];
      filterExpression.push(filterPocketExpression);

      return filterExpression;
   }, [selectedPocket, show2030, show2035, showAllPockets]);

   const geojson = useMemo(() => getLargeLoadsGeojson(largeLoads), [largeLoads]);

   const loadImgaes = useCallback(() => {
      if (map === undefined) return;
      setLoadingImages(true);
      addMapImages(map, IMAGES).then(() => setLoadingImages(false));
   }, [map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);

   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   if (!map || loadingImages) return null;

   return (
      <>
         <Source id={LAYER_ID} type="geojson" data={geojson}>
            {/* <Layer id={LAYER_ID} type="circle" paint={{ 'circle-radius': 5, 'circle-color': '#ff00ff' }} /> */}
            <Layer
               id={LAYER_ID} type="symbol" filter={filter}
               layout={{
                  'icon-size': .2,
                  'icon-image': 'large-load-image-powerdev',
                  'icon-allow-overlap': true,
               }} />
         </Source>
      </>
   );
};

export default LargeLoadLayer;
