import { Select } from 'antd';
import { API_ROUTES } from 'power/constants';
import { INodeMapPageQuery } from 'power/types';
import { DATA_PROVIDER, NODE_MAP_DATA_COMPONENT, TBX_VALUE_TYPE } from 'power/types/enum';
import { LocationInfo } from 'power/types/ICapacityOverview';
import { useIso } from 'providers/useIso';
import React, { memo, useMemo } from 'react';
import PowerTimeSeries from './charts/PowerTimeSeries/PowerTimeSeries';
import classes from './index.module.scss';

type Props = {
  nodeQuery: Partial<INodeMapPageQuery>;
  nodes: { value: number; label: string; lngLat: number[] }[];
  location: LocationInfo;
  selectedNodeId: number;
  selectedSubstationId: number;
  setSelectedNode: (nodeId: number) => void;
};

const NodeInfo: React.FC<Props> = ({ nodeQuery, nodes, location, selectedNodeId, selectedSubstationId, setSelectedNode }) => {
   const { iso } = useIso();
   const node = useMemo(() => nodes.find((item) => item.value === selectedNodeId), [nodes, selectedNodeId]);

   return (
      <div className={classes.container}>
         <div className={classes.nodeSelect}>
            {nodes.length === 1 && node ? (
               <div className={classes.singleNode}>{`Node: ${node?.label}`}</div>
            ) : (
               <Select
                  value={selectedNodeId} onChange={setSelectedNode} options={nodes.map((item, index) => ({ label: `Node: ${item.label}`, value: item.value }))}
                  style={{ width: '100%' }} suffixIcon={<h3>({nodes.length})</h3>} />
            )}
         </div>

         <div className={classes.hubZone}>
            {location.zoneName && (
               <div>
                  <div className={classes.title1}>Zone</div>
                  <div className={classes.hubName}>{location.zoneName}</div>
               </div>
            )}

            {location.hubName && (
               <div>
                  <div className={classes.title1}>{iso === DATA_PROVIDER.ISONE && location.hubName.startsWith('.Z.') ? 'Zone' : 'Hub'}</div>
                  <div className={classes.hubName}>{location.hubName}</div>
               </div>
            )
            }
         </div>

         {node && (
            <div className={classes.nodeInfo}>
               <PowerTimeSeries
                  title="LMP Trend" apiRoute={API_ROUTES.NODE.TimeSeries} query={{ component: NODE_MAP_DATA_COMPONENT.LMP, nodeId: node.value }}
                  moreQuery={nodeQuery} selectedSubstationId={selectedSubstationId} overviewHash="#lmp" />
               <PowerTimeSeries
                  title="MCC Trend" apiRoute={API_ROUTES.NODE.TimeSeries} query={{ component: NODE_MAP_DATA_COMPONENT.MCC, nodeId: node.value }}
                  moreQuery={nodeQuery} selectedSubstationId={selectedSubstationId} overviewHash="#mcc" />
               <PowerTimeSeries
                  title={`TB${nodeQuery.tbxType} ${nodeQuery.tbxValueType === TBX_VALUE_TYPE.Performance ? 'Performance' : 'Average'} Trend`}
                  apiRoute={API_ROUTES.NODE.TimeSeries}
                  query={{ component: NODE_MAP_DATA_COMPONENT.TBX, nodeId: node.value }}
                  moreQuery={nodeQuery}
                  selectedSubstationId={selectedSubstationId}
                  yTitle={nodeQuery.tbxValueType === TBX_VALUE_TYPE.Performance ? '%' : 'MW/h'}
                  overviewHash="#tbx"
               />
               <PowerTimeSeries
                  title="Arbitrage" apiRoute={API_ROUTES.NODE.TimeSeries} query={{ component: NODE_MAP_DATA_COMPONENT.BATTER_SITING, nodeId: node.value }}
                  moreQuery={nodeQuery} selectedSubstationId={selectedSubstationId} yTitle="$/kW-year"
                  overviewHash="#arbitrage" />
               <PowerTimeSeries
                  title="Basis Risk Trend" apiRoute={API_ROUTES.NODE.TimeSeries} query={{ component: NODE_MAP_DATA_COMPONENT.BASIS_RISK, nodeId: node.value }}
                  moreQuery={nodeQuery} selectedSubstationId={selectedSubstationId} overviewHash="#basis-risk" />
            </div>
         )}
      </div>
   );
};

export default memo(NodeInfo);
