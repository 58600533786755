// import { PowerMap } from 'power/components/maps';
import { Flex } from 'antd';
import { GenerationPocketBindingContingency, GenerationPocketSummary } from 'power/types';
import { FC } from 'react';
import BindingContingenciesTable from './BindingContingenciesTable';
import SummaryTable from './SummaryTable';
import classes from './TableView.module.scss';

type Props = {
  pockets: { id: number; name: string }[];
  summary: GenerationPocketSummary[];
  bindingContingencies: GenerationPocketBindingContingency[];
};

const TableView: FC<Props> = ({ pockets, summary, bindingContingencies }) => (
   <div className={classes.container}>
      <Flex vertical justify="center" align="center">
         <SummaryTable
            pockets={pockets} summary={summary} targetYear={2030}
            case_type="ContractCase" />
         <SummaryTable
            pockets={pockets} summary={summary} targetYear={2035}
            case_type="PolicyCaseLowerDemand" />
         <SummaryTable
            pockets={pockets} summary={summary} targetYear={2035}
            case_type="PolicyCaseHigherDemand" />
         <BindingContingenciesTable pockets={pockets} bindingContingencies={bindingContingencies} />
      </Flex>
   </div>
);

export default TableView;
