import { ApiClients } from 'ApiClients';
import { GenerationPocketLargeLoad } from 'power/types';
import { useEffect, useState } from 'react';

export const useLargeLoads = () => {
   const [loadingLargeLoads, setLoadingLargeLoads] = useState<boolean>(true);
   const [largeLoadsList, setLargeLoadsList] = useState<GenerationPocketLargeLoad[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketLargeLoadList()
         .then((res) => {
            setLargeLoadsList(res);
         })
         .finally(() => setLoadingLargeLoads(false));
   }, []);

   return { loadingLargeLoads, largeLoadsList };
}
