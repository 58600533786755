import { ApiClients } from 'ApiClients';
import { FeatureCollection, Polygon } from 'geojson';
import { useEffect, useState } from 'react';

export const usePockets = () => {
   const [loadingPockets, setLoadingPockets] = useState<boolean>(true);
   const [pockets, setPockets] = useState<FeatureCollection<Polygon, { id: number; name: string; regionId: number; }> | null>(null);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketPolygons()
         .then((res) => {
            setPockets(res);
         })
         .finally(() => setLoadingPockets(false));
   }, []);

   return { loadingPockets, pockets };
}
