import { SymbolLayout, SymbolPaint } from 'mapbox-gl';

/* enum PowerPlantCapacityClass {
  // if <0 = unknown
  'UNKNOWN' = 'UNKNOWN',
  '<10' = '<10',
  '20-29' = '20-29',
  '30-39' = '30-39',
  '40-49' = '40-49',
  '50-99' = '50-99',
  '100-149' = '100-149',
  '150-249' = '150-249',
  '250-499' = '250-499',
  '500-749' = '500-749',
  '750-999' = '750-999',
  '1000-2499' = '1000-2499',
  '2500-3499' = '2500-3499',
  '3500-4799' = '3500-4799',
  '>4800' = '>4800',
} */

const LAYOUT = (zone = false): SymbolLayout => ({
   /* 'icon-size': [
      'match',
      ['get', 'price'],
      'UNKNOWN',
      0.8,
      '<10',
      0.8,
      '20-29',
      0.85,
      '30-39',
      0.9,
      '40-49',
      0.95,
      '50-99',
      1,
      '100-149',
      1.05,
      '150-249',
      1.1,
      '250-499',
      1.15,
      '500-749',
      1.2,
      '750-999',
      1.25,
      '1000-2499',
      1.3,
      '2500-3499',
      1.35,
      '3500-4799',
      1.4,
      '>4800',
      1,
      1,
   ], */
   // 'icon-image': ['case', ['==', ['get', 'locationType'], 'hub'], 'Hub-image-powerdev', ['==', ['get', 'locationType'], 'node'], 'Node-image-powerdev', ['==', ['get', 'locationType'], 'bus'], 'Bus-image-powerdev', 'default-icon'],
   'icon-image': zone ? 'Hub-image-powerdev' : 'Node-image-powerdev',
   'icon-size': 0.2,
   /* 'icon-image': 'Test-image-powerdev', */
   /* 'icon-image': 'Test2-image-powerdev', */
   'icon-allow-overlap': true,
});

const PAINT: SymbolPaint = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
