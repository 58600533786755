import { Line } from '@ant-design/plots';
import { Flex, Radio, Spin } from 'antd';
import { mainColorSet } from 'constants/NewColors';
import { FuturePriceRequest } from 'power/types';
import { MARKET_TYPE, NYISO_ZONE, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';
import classes from './index.module.scss';
import useFutureTbxTimeseries from './useFutureTbxTimeseries';

const MONTH_LAST_DAY = (month: number) => {
   if (month === 2) return 28;
   if ([4, 6, 9, 11].includes(month)) return 30;
   return 31;
};

type Props = {
   zone_id: NYISO_ZONE;
  query: FuturePriceRequest;
  loadingSize?: [number, number];
};

const FutureTbxTimeseriesChart: FC<Props> = ({ zone_id, query, loadingSize = [352, 470] }) => {
   const { loading, data, fetch } = useFutureTbxTimeseries();
   const [tbx, setTbx] = useState(TXBX_COMPONENT.Tb4);

   useEffect(() => {
      // start_date
      const startMonth = query.month > 0 ? query.month.toString().padStart(2, '0') : '01';
      const endMonth = query.month > 0 ? query.month.toString().padStart(2, '0') : '12';
      const startDate = `${query.year}-${startMonth}-01`;
      const endDate = `${query.year}-${endMonth}-${MONTH_LAST_DAY(parseInt(endMonth))}`;
      // fetch({ scenario: query.scenario, market_type: query.market_type, tbx, zone, start_date: `${query.year}-${query.month?.toString().padStart(2, '0') ?? '01'}-01T00:00:00.000`, end_date: `${query.year}-${query.month?.toString().padStart(2, '0') ?? '12'}-31T00:00:00.000` });
      fetch({ scenario: query.scenario, market_type: query.market_type, tbx, zone_id, start_date: startDate, end_date: endDate });
   }, [fetch, query.market_type, query.month, query.scenario, query.year, tbx, zone_id]);

   return (
      <div className={classes.chart} style={{ height: loadingSize[1] }}>
         <Flex justify="space-between">
            <h3 style={{ margin: '4px 0 8px 4px' }}>
          Future Zone {NYISO_ZONE[zone_id]} - TB{tbx} {query.market_type === MARKET_TYPE.DayAhead ? 'Day-Ahead' : 'Real Time'}
            </h3>
            <Radio.Group value={tbx} onChange={(e) => setTbx(e.target.value)} style={{ marginTop: 6 }}>
               <Radio value={TXBX_COMPONENT.Tb1}>TB1</Radio>
               <Radio value={TXBX_COMPONENT.Tb2}>TB2</Radio>
               <Radio value={TXBX_COMPONENT.Tb3}>TB3</Radio>
               <Radio value={TXBX_COMPONENT.Tb4}>TB4</Radio>
            </Radio.Group>
         </Flex>
         {loading ? (
            <div className={classes.loading}>
               <Spin size="large" />
            </div>
         ) : data.length ? (
            <Line
               data={data}
               xField="date"
               yField="price"
               color={mainColorSet}
               style={{ height: loadingSize[1] - 60 }}
               yAxis={{ title: { text: 'Price ($/MWh)' }, min: data.reduce((acc, cur) => Math.min(acc, cur.price), Infinity), max: data.reduce((acc, cur) => Math.max(acc, cur.price), -Infinity) }}
               xAxis={{ 
                  label: {
                     formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.daily),
                     autoRotate: true,
                  },
               }}
               legend={{ position: 'top' }}
            />
         ) : (
            <div className={classes.loading}>No data available</div>
         )}
      </div>
   );
};

export default FutureTbxTimeseriesChart;
