import { PowerCol, PowerRow } from 'power/components/base/grid';
import LazyComponent from 'power/components/base/LazyComponent';
import { FutureHeatmapRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE, NYISO_ZONE } from 'power/types/enum';
import { useMemo, useState } from 'react';
import { FUTURE_YEARS } from '../FUTURE_YEARS';
import FutureHeatmapFilter from './FilterBar';
import classes from './index.module.scss';
import { LazyHeatmap } from './LazyHeatmap/LazyHeatmap';
import { LegendControl } from './legendControl/LegendControl';

const FutureHeatmapTab = () => {
   const [query, setQuery] = useState<Omit<FutureHeatmapRequest, 'year'>>({ market_type: MARKET_TYPE.DayAhead, zone_id: NYISO_ZONE.K, scenario: FUTURE_SCENARIO.REF });

   const [min, setMin] = useState(0);
   const [max, setMax] = useState(120);

   const minMaxValues = useMemo(() => ({ min, max }), [min, max]);

   return (
      <div className={classes.fullContainer}>
         <div className={classes.filterBar}>
            <FutureHeatmapFilter loading={false} query={query} onSubmit={setQuery} />
         </div>
         <div className={classes.container}>
            <PowerRow gutter={[16, 16]}>
               {FUTURE_YEARS.map((year) => (
                  <PowerCol key={year} span={12} xxl={8}>
                     <LazyComponent key={JSON.stringify(query)}>
                        <LazyHeatmap query={query} year={year} minMaxValues={minMaxValues} />
                     </LazyComponent>
                  </PowerCol>
               ))}
            </PowerRow>
         </div>
         <LegendControl
            min={min} max={max} setMin={setMin}
            setMax={setMax} tickCount={9} />
      </div>
   );
};

export default FutureHeatmapTab;
