import { ApiClients } from 'ApiClients';
import { FeatureCollection, Point } from 'geojson';
import { IHeadroomStaticLayers } from 'power/types/IHeadroomStaticPointFeature';
import { useIso } from 'providers/useIso';
import { useCallback, useEffect, useState } from 'react';
import { headroomStaticPointFeaturesToGeojson } from './utils';

const useHeadroomStaticLayers = () => {
   const { iso } = useIso();
   const [staticLayer, setStaticLayer] = useState<IHeadroomStaticLayers | undefined>(undefined);
   const [geojsons, setGeojsons] = useState<
    | {
        generator: FeatureCollection<Point>;
        iq: FeatureCollection<Point>;
        load: FeatureCollection<Point>;
        retirement: FeatureCollection<Point>;
        transformer: FeatureCollection<Point>;
      }
    | undefined
  >(undefined);

   const fetch = useCallback(() => ApiClients.getInstance()
      .headroomStaticLayers(iso)
      .then((data) => {

         if (data) setStaticLayer(data);
      })
      .catch((error) => {
         console.error(error);
      }), [iso]);

   useEffect(() => {
      if (!staticLayer) return;
      const newGeojsons = headroomStaticPointFeaturesToGeojson(staticLayer);
      setGeojsons(newGeojsons);
   }, [staticLayer]);

   return {
      geojsons,
      fetch,
   };
};

export default useHeadroomStaticLayers;
