import { Column, ColumnConfig } from '@ant-design/plots';
import CardPanel from 'components/ui/CardPanel';
import { usePowerTimeSeries } from 'modules/HomeMap/SiderControl/container/content/nodeInfo/charts/PowerTimeSeries/usePowerTimeSeries';
import { API_ROUTES } from 'power/constants';
import IArbitrageTimeseriesRequest from 'power/types/IArbitrageTimeseriesRequest';
import { DATA_SUM_TYPE, MARKET_TYPE, PERIOD_TYPE, PRICE_COMPONENT, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';

type Props = {
  nodeId: number;
  marketType: MARKET_TYPE;
  hour: TXBX_COMPONENT;
};
const YearlyArbitrageTimeSeriesChart: FC<Props> = ({ nodeId, marketType, hour }) => {
   const { loading, fetch, data } = usePowerTimeSeries<IArbitrageTimeseriesRequest>(API_ROUTES.Arbitrage.TimeSeries);

   const fetchData = useCallback(
      (reload?: boolean) => {
         fetch(
            {
               nodeId,
               hours: [hour],
               periodType: PERIOD_TYPE.last_5_year,
               sumType: DATA_SUM_TYPE.Yearly,
               components: [PRICE_COMPONENT.BATTERY_CHARGING_MWH, PRICE_COMPONENT.BATTERY_DISCHARGING_MWH],
               dayaheadMarket: marketType === MARKET_TYPE.DayAhead ? 1 : 0,
               realtimeMarket: marketType === MARKET_TYPE.Realtime ? 1 : 0,
            },
            reload,
         );
      },
      [fetch, hour, marketType, nodeId],
   );

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   const datas = useMemo(() => {
      if(data?.result?.data) {
         const getSeries = (seriesVal: number) => {
            if(seriesVal === PRICE_COMPONENT.BATTERY_CHARGING_MWH) {
               return 'Charging MWh/MW-month';
            } else if(seriesVal === PRICE_COMPONENT.BATTERY_DISCHARGING_MWH) {
               return 'Discharging MWh/MW-month';
            }
            return '';
         };

         const data2 = data.result.data.map((d) => ({...d, series: getSeries(d.id % 1000) }));

         return data2;
      }
      return [];
   }, [data]);

   const columnConfig: ColumnConfig = useMemo(() => ({
      className: 'antd-chart',
      data: datas,
      xField: 'x',
      yField: 'y',
      isGroup: true,
      seriesField: 'series',
      color: ['#4ddc8b', '#ff6f30'],
      marginRatio: 0,
      xAxis: {
         label: {
            formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.yearly),
            autoRotate: true,
         },
      },
      legend: {
         position: 'bottom',
         padding: [24, 0, 0, 0],
         flipPage: false,
      },
      tooltip: {
         title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.yearly),
         /* offset: 100, */
         showCrosshairs: true,
      },
   }), [datas]);

   return (
      <CardPanel
         title={`${marketType === MARKET_TYPE.DayAhead ? 'DA' : 'RT'} Yearly ${hour} hour`} defaultViewMode="chart" status={loading || !(data?.result?.data) ? 'loading' : 'success'}
         fullScreen downloadURL={data?.downloadUrl} refreshClick={() => fetchData(true)}>
         {() => (
            <>
               <div style={{ margin: '4px 4px', color: '#808080'}}>MWh/MW-month</div>
               <Column {...columnConfig} />
            </>
         )}
      </CardPanel>
   );
};

export default YearlyArbitrageTimeSeriesChart;
