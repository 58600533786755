import { Col, ColProps } from 'antd';
import { FC } from 'react';

const filterStyle = { borderRight: '1px solid #C9C1C1' };

type Props = ColProps & {
  filter?: boolean;
};
const PowerCol: FC<Props> = ({ filter = false, style, ...props }) => <Col {...props} style={filter ? { ...style, ...filterStyle } : style} />;

export default PowerCol;
