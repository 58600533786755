// import { PowerMap } from 'power/components/maps';
import { Column } from '@ant-design/plots';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
    title: string; 
    data: {
      pocketName: string;
      case_type: 'PolicyCaseLowerDemand' | 'PolicyCaseHigherDemand' | 'ContractCase' | 'PolicyCaseS1';
      value: number;
  }[];
  seriesField: string;
}


const PocketAverageChart: FC<Props> = ({ title, data, seriesField }) => {
   if (!data.length) return null;

   return (
      <div className={classes.chart}>
         <h4 style={{ textAlign: 'center' }}>{title}</h4>
         <Column
            data={data} xField="pocketName" yField="value"
            yAxis={{ title: { text: 'Average Curtailment' }, label: { formatter: (v) => `${v}%` } }}
            /* xAxis={{ title: { text: 'Pocket' } }} */
            seriesField={seriesField} /* isStack={true} */ isGroup={true}
            style={{ width: '100%', height: 400 }}
            legend={{ position: 'top' }}
         />
      </div>
   );
};

export default PocketAverageChart;
