import { ApiClients } from 'ApiClients';
import { GenerationPocketConstraintResponse } from 'power/types';
import { useEffect, useState } from 'react';

export const useConstraints = () => {
   const [loadingConstraintList, setLoadingConstraintList] = useState<boolean>(true);
   const [constraintList, setConstraintList] = useState<GenerationPocketConstraintResponse>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketConstraintList()
         .then((res) => {
            // unique constraint id
            /* const list = res.reduce((acc: GenerationPocketConstraint[], item) => {
               if (!acc.find((x) => x.name_id === item.name_id)) {
                  acc.push(item);
               }
               return acc;
            }, []); */

            setConstraintList(res);
            // setConstraintList(list);
            // setConstraintList(list.filter((x) => x.generation_pocket_id === 14));
         })
         .finally(() => setLoadingConstraintList(false));
   }, []);

   return { loadingConstraintList, constraintList };
}
