import { Expression } from 'mapbox-gl';
import { IMAGE_PATHS } from 'power/constants';
import PLANT_STYLE from 'power/constants/map/styles/PLANT_STYLE';
import addMapImages from 'power/map/helper/addMapImages';
import { GenerationPocketPlant } from 'power/types';
import { FUEL_TYPE } from 'power/types/enum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { getPlantsGeojson } from './helper';

const LAYER_ID = 'GENERATION_POCKETS_PLANTS';

export const PLANT_IMAGES: { name: string; url: string }[] = [
   { name: 'Wind-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Wind) },
   { name: 'Solar-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Solar) },
   { name: 'Hydroelectric-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Hydro) },
   { name: 'Other-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Other) },
   { name: 'default-icon', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Other) },
];

type Props = {
  mapId: string;
  plants: GenerationPocketPlant[];
  selectedPocket: number | null;
  show2035: boolean;
  show2030: boolean;
  showAllPockets: boolean;
  typeFilter: ('Solar' | 'Solar-cc' | 'Solar-lo' | 'Solar-hi' | 'Solar-hilo' | 'Wind' | 'Wind-cc' | 'Wind-lo' | 'Wind-hi' | 'Wind-hilo')[];
};

const PlantsLayer: React.FC<Props> = ({ mapId, plants, selectedPocket, show2030, show2035, showAllPockets, typeFilter }) => {
   const { [mapId]: map } = useMap();
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const filter = useMemo(() => {
      const filterExpression: Expression = ['all'];

      const filterTargetYearExpression: Expression = ['any'];
      if (show2030) {
         filterTargetYearExpression.push(['in', 2030, ['get', 'target_years']]);
      }
      if (show2035) {
         filterTargetYearExpression.push(['in', 2035, ['get', 'target_years']]);
      }
      filterExpression.push(filterTargetYearExpression);

      // type: 'Wind' | 'Solar'
      // case_type: 'PolicyCaseLowerDemand' | 'PolicyCaseHigherDemand' | 'ContractCase' | 'PolicyCaseLowerAndHigherDemand'
      const filterTypeExpression: Expression = ['any'];
      const typeChildToCaseType = (typeChild: 'cc' | 'lo' | 'hi' | 'hilo') => {
         switch (typeChild) {
         case 'cc':
            return 'ContractCase';
         case 'lo':
            return 'PolicyCaseLowerDemand';
         case 'hi':
            return 'PolicyCaseHigherDemand';
         case 'hilo':
            return 'PolicyCaseLowerAndHigherDemand';
         default:
            return '';
         }
      };
      typeFilter.forEach((type) => {
         const typeChild = type.split('-');
         if (typeChild.length === 2) {
            filterTypeExpression.push(['all', ['in', typeChild[0], ['get', 'type']], ['==', typeChildToCaseType(typeChild[1] as any), ['get', 'case_type']]]);
         }
      });
      filterExpression.push(filterTypeExpression);

      if (showAllPockets || selectedPocket === null) return filterExpression;

      const filterPocketExpression: Expression = ['in', selectedPocket, ['get', 'generation_pocket_ids']];
      filterExpression.push(filterPocketExpression);

      return filterExpression;
   }, [selectedPocket, show2030, show2035, showAllPockets, typeFilter]);

   const geojson = useMemo(() => getPlantsGeojson(plants), [plants]);

   const loadImgaes = useCallback(() => {
      if (map === undefined) return;
      setLoadingImages(true);
      addMapImages(map, PLANT_IMAGES).then(() => setLoadingImages(false));
   }, [map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);

   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   /* useEffect(() => {
      if (!map) return;

      // set cursor to pointer when hovering over pocket
      map.on('mousemove', (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [LAYER_ID] });
         map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      return () => {
         map.off('mousemove', () => {});
      }
   }, [map]); */

   if (!map || loadingImages) return null;

   return (
      <>
         <Source id={LAYER_ID} type="geojson" data={geojson}>
            {/* <Layer
               id={LAYER_ID} type="symbol" layout={PLANT_STYLE.LAYOUT}
               paint={PLANT_STYLE.PAINT} filter={filter} /> */}
            <Layer
               id={`${LAYER_ID}-circle`} type="circle" layout={PLANT_STYLE.LAYOUT_CIRCLE}
               paint={PLANT_STYLE.PAINT_CIRCLE} filter={filter} />
            <Layer
               id={`${LAYER_ID}-circle-text`} type="symbol" layout={PLANT_STYLE.LAYOUT_CIRCLE_TEXT}
               paint={PLANT_STYLE.PAINT_CIRCLE_TEXT} filter={filter} />
         </Source>
      </>
   );
};

export default PlantsLayer;
