import { ApiClients } from 'ApiClients';
import { GenerationPocketAverageCurtailment } from 'power/types';
import { useEffect, useState } from 'react';

export const useAverageCurtailment = () => {
   const [loadingAverageCurtailment, setLoadingAverageCurtailment] = useState<boolean>(true);
   const [averageCurtailments, setAverageCurtailments] = useState<GenerationPocketAverageCurtailment[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketAverageCurtailments()
         .then((res) => {
            setAverageCurtailments(res);
         })
         .finally(() => setLoadingAverageCurtailment(false));
   }, []);

   return { loadingAverageCurtailment, averageCurtailments };
};
