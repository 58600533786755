import { Flex, Spin } from 'antd';
import PowerMap from 'power/map';
import { useCallback, useEffect, useState } from 'react';
import ChartView from './charts/ChartView';
import GenerationPocketControlSection from './control/GenerationPocketControlSection/GenerationPocketControlSection';
import ShowHide from './control/ShowHide/ShowHide';
import { useData } from './hooks/useData';
import classes from './index.module.scss';
import ConstraintsLayer from './layers/ConstraintsLayer';
import LargeLoadLayer from './layers/LargeLoadLayer';
import NodesLayer from './layers/NodesLayer';
import PlantsLayer from './layers/PlantsLayer';
import PocketsLayer from './layers/PocketsLayer';
import TransmissionLinesLayer from './layers/TransmissionLinesLayer';
import SiderBottom from './siderBottom/SiderBottom';
import { GenerationPocketsSider } from './siderLeft';
import TableView from './tables/TableView';
import { GPLayers, INITIAL_GP_LAYERS } from './type';

const MAP_ID = 'generation-pockets-v2';
const SIDER_WIDTH = 550;

export const GenerationPockets = () => {
   const [showAllPockets, setShowAllPockets] = useState(false);
   /* const [show2030, setShow2030] = useState(true);
   const [show2035, setShow2035] = useState(true); */
   const {
      loading,
      pockets,
      selectedPocket,
      setSelectedPocket: setSelectedPocketState,
      selectedConstraints,
      constraintList,
      selectedInfos,
      infoList,
      transmissionLinesList,
      plantsList,
      largeLoadsList,
      summary,
      bindingContingencies,
      descriptionPocket,
      descriptionRegion,
      averageCurtailments,
   } = useData();

   const [gpLayer, setGpLayer] = useState<GPLayers>(INITIAL_GP_LAYERS);

   const [leftSiderOpen, setLeftSiderOpen] = useState<boolean>(true);
   const [bottomSiderOpen, setBottomSiderOpen] = useState<boolean>(false);
   const [tableViewOpen, setTableViewOpen] = useState<boolean>(false);
   const [chartViewOpen, setChartViewOpen] = useState<boolean>(false);

   const setSelectedPocket = useCallback(
      (id: number | null) => {
         setSelectedPocketState(id);
         if (id) setBottomSiderOpen(true);
         else setBottomSiderOpen(false);
      },
      [setSelectedPocketState, setBottomSiderOpen],
   );

   useEffect(() => {
      if (pockets) {
         setGpLayer((prev) => prev && { ...prev, loading: false });
      }
   }, [loading, pockets]);

   return (
      <div className={classes.fullContainer}>
         <div className={classes.container}>
            <Flex vertical={false} style={{ height: '100%' }}>
               <div style={{ width: leftSiderOpen ? SIDER_WIDTH : 0, height: '100%', borderRight: '1px solid #c9c1c1' }}>
                  {pockets && (
                     <GenerationPocketsSider
                        geojson={pockets}
                        selectedPocket={selectedPocket}
                        setSelectedPocket={setSelectedPocket}
                        constraints={selectedConstraints.length ? selectedConstraints : constraintList}
                        infos={selectedInfos.length ? selectedInfos : infoList}
                        gpLayer={gpLayer}
                        setGpLayer={setGpLayer}
                        showAllPockets={showAllPockets}
                        setShowAllPockets={setShowAllPockets}
                     />
                  )}
               </div>
               <div style={{ width: `calc(100% - ${leftSiderOpen ? SIDER_WIDTH : 0}px)`, height: bottomSiderOpen ? 'calc(100% - 200px)' : '100%', position: 'relative' }}>
                  <PowerMap id={MAP_ID} customSiderRightControls={[<GenerationPocketControlSection mapId={MAP_ID} gpLayer={gpLayer} setGpLayer={setGpLayer} />]}>
                     {pockets && !gpLayer.loading && (
                        <>
                           <PocketsLayer
                              mapId={MAP_ID} geojson={pockets} selectedPocket={selectedPocket}
                              setSelectedPocket={setSelectedPocket} show2030={gpLayer.year2030} show2035={gpLayer.year2035}
                              showAllPockets={showAllPockets} />
                           {gpLayer.visible.tlUpgrade && (
                              <TransmissionLinesLayer
                                 mapId={MAP_ID} transmissionLines={transmissionLinesList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.constraint && (
                              <ConstraintsLayer
                                 mapId={MAP_ID} constraints={constraintList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.largeLoad && (
                              <LargeLoadLayer
                                 mapId={MAP_ID} largeLoads={largeLoadsList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.plant && (
                              <PlantsLayer
                                 mapId={MAP_ID} plants={plantsList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets}
                                 typeFilter={gpLayer.filter.plant} />
                           )}
                           {gpLayer.visible.nodes && <NodesLayer mapId={MAP_ID} />}
                        </>
                     )}

                     <ShowHide
                        mapId={MAP_ID} leftSiderOpen={leftSiderOpen} setLeftSiderOpen={setLeftSiderOpen}
                        tableViewOpen={tableViewOpen} setTableViewOpen={setTableViewOpen} chartViewOpen={chartViewOpen}
                        setChartViewOpen={setChartViewOpen} />
                  </PowerMap>

                  <SiderBottom
                     mapId={MAP_ID}
                     gpLayer={gpLayer}
                     open={bottomSiderOpen && selectedPocket !== null}
                     onClose={() => setBottomSiderOpen(false)}
                     selectedPocket={selectedPocket}
                     pockets={pockets?.features.map((x) => ({ id: x.properties.id, name: x.properties.name, regionId: x.properties.regionId })) || []}
                     descriptionPocket={descriptionPocket}
                     descriptionRegion={descriptionRegion}
                  />

                  {tableViewOpen && <TableView pockets={pockets?.features.map((x) => ({ id: x.properties.id, name: x.properties.name })) || []} summary={summary} bindingContingencies={bindingContingencies} />}

                  {chartViewOpen && <ChartView pockets={pockets?.features.map((x) => ({ id: x.properties.id, name: x.properties.name })) || []} averageCurtailments={averageCurtailments} />}
               </div>
            </Flex>
            {loading && <Spin className={classes.loading} />}
         </div>
      </div>
   );
};
