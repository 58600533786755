import { GeoJsonProperties } from 'geojson';
import { usePowerMap } from 'power/map/usePowerMap';
import React, { useEffect, useMemo, useState } from 'react';
import { Popup, useMap } from 'react-map-gl';
import IqLayers from './IqLayer';
import IqPopup from './IqLayer/popup/IqPopup';
import PowerPlantLayer from './PowerPlantLayer';
import PowerPlantPopup from './PowerPlantLayer/popup/PowerPlantPopup';
import TransmissionLinesLayer from './TransmissionLinesLayer';
import TlPopup from './TransmissionLinesLayer/popup/TlPopup';
import ZoneLayer from './ZoneLayer';

const StaticLayers: React.FC<{ mapId: string }> = ({ mapId }) => {
   const { staticLayers } = usePowerMap();
   const { [mapId]: map } = useMap();

   const [popup, setPopup] = useState<{ location: mapboxgl.LngLat; properties: GeoJsonProperties; layer: 'iq' | 'powerPlant' | 'tl' }>();

   const setPopupProp = (location: mapboxgl.LngLat, properties: GeoJsonProperties, layer: 'iq' | 'powerPlant' | 'tl') => setPopup({
      location,
      properties,
      layer,
   });

   const infoKeyValuesForGenerator = useMemo(() => {
      if (!popup || popup.layer !== 'powerPlant') return undefined;
      const props = Object.entries(popup.properties || {}).map(([key, value]) => ({ key, value }));
      return props.filter(({ key }) => key !== 'icon' && key !== 'ID');
   }, [popup]);

   const infoKeyValuesForIQ = useMemo(() => {
      if (!popup || popup.layer !== 'iq') return undefined;
      const props = Object.entries(popup.properties || {}).map(([key, value]) => ({ key, value }));
      return props.filter(({ key }) => key !== 'icon' && key !== 'ID');
   }, [popup]);

   useEffect(() => {
      if (popup) {
         if (!staticLayers?.allActive) {
            setPopup(undefined);
         }

         if(popup.layer === 'powerPlant') {
            if(!staticLayers?.powerPlants?.filter?.active) {
               setPopup(undefined);
            }
         }

         if(popup.layer === 'iq') {
            if(!staticLayers?.iq?.filter?.active) {
               setPopup(undefined);
            }
         }

         if(popup.layer === 'tl') {
            if(!staticLayers?.transmissionLines?.filter?.active) {
               setPopup(undefined);
            }
         }
      }
   }, [popup, staticLayers?.powerPlants?.filter?.active, staticLayers?.iq?.filter?.active, staticLayers?.transmissionLines?.filter?.active, staticLayers?.allActive]);

   if (!staticLayers || !staticLayers.allActive || !map) return null;

   return (
      <>
         {staticLayers.zone && <ZoneLayer map={map} layerProps={staticLayers.zone} />}
         {staticLayers.transmissionLines && <TransmissionLinesLayer map={map} layerProps={staticLayers.transmissionLines} setPopup={setPopupProp} />}
         {staticLayers.iq && <IqLayers map={map} iq={staticLayers.iq} setPopup={setPopupProp} />}
         {staticLayers.powerPlants && <PowerPlantLayer map={map} powerPlants={staticLayers.powerPlants} setPopup={setPopupProp} />}
         
         {popup?.properties && popup.layer === 'tl' && staticLayers.transmissionLines && (
            <Popup
               longitude={popup.location.lng} latitude={popup.location.lat} onClose={() => setPopup(undefined)}
               closeOnClick={false}>
               <TlPopup key={popup.properties.id} feature={popup.properties as any} />
            </Popup>
         )}

         {popup?.properties && popup.layer === 'powerPlant' && infoKeyValuesForGenerator && (

            <>{staticLayers.powerPlants?.clickedComponent ? <staticLayers.powerPlants.clickedComponent key={popup.properties.id} ids={[popup.properties.id]} onClose={() => setPopup(undefined)} /> :
               (<Popup
                  longitude={popup.location.lng}
                  latitude={popup.location.lat}
                  closeButton
                  closeOnMove={false}
                  onClose={() => {
                     setPopup(undefined);
                  }}
                  closeOnClick={false}
               >
                  <PowerPlantPopup key={popup.properties.id} id={popup.properties.id} />
               </Popup>)}</>
         )}

         {popup?.properties?.id && popup.layer === 'iq' && infoKeyValuesForIQ && (
            <Popup
               longitude={popup.location.lng}
               latitude={popup.location.lat}
               closeButton
               closeOnMove={false}
               onClose={() => {
                  setPopup(undefined);
               }}
               closeOnClick={false}
            >
               <IqPopup key={popup.properties.id} id={popup.properties.id} />
            </Popup>
         )}
      </>
   );
};

export default StaticLayers;
