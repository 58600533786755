import { ApiClients } from 'ApiClients';
import { FeatureCollection, Point } from 'geojson';
import { IPowerPlantLayerProperties } from 'power/types/map';
import { useIso } from 'providers/useIso';
import { useEffect, useState } from 'react';

const usePowerPlantLayer = (active: boolean) => {
   const { iso } = useIso();
   const [powerPlantLayer, setPowerPlantLayer] = useState<FeatureCollection<Point, IPowerPlantLayerProperties> | undefined>(undefined);

   useEffect(() => {
      if (!active) return;
      if (powerPlantLayer) return;

      let isMounted = true;

      const fetch = async () => ApiClients.getInstance()
         .mapPowerPlant(iso)
         .then((data) => {
            if (data && isMounted) setPowerPlantLayer(data);
         })
         .catch((error) => {
            console.error(error);
            if (isMounted) setPowerPlantLayer(undefined);
         });

      fetch();

      return () => {
         isMounted = false;
      };
   }, [active, iso, powerPlantLayer]);

   return {
      powerPlantLayer,
   };
};

export default usePowerPlantLayer;
