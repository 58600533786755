import FUTURE_ZONE_STYLE from 'power/constants/map/styles/FUTURE_ZONE_STYLE';
import { FuturePrice } from 'power/types';
import { NYISO_ZONE, VECTOR_LAYER_ID } from 'power/types/enum';
import { utils } from 'power/utils';
import React, { useEffect, useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { getLegendColorExpression } from './getLegendColorExpression';

const { /* layerType, */ layerId, sourceUrl, sourceLayer, /* layerLayout, layerPaint, */ layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.NYISO_ZONE);

type Props = {
  mapId: string;
  prices: FuturePrice[];
  minmax: number[];
};

const FutureZoneLayer: React.FC<Props> = ({ mapId, prices, minmax }) => {
   const { [mapId]: map } = useMap();

   useEffect(() => {
      if (!map) return;

      map.on('data', (e) => {
         if (e.dataType === 'source' && e.sourceId === layerId) {
            const features = map.querySourceFeatures(layerId, {
               sourceLayer: layerId,
            });

            features.forEach((feature) => {
               const zone_id = NYISO_ZONE[feature.properties?.Zone as any as keyof typeof NYISO_ZONE];
               const price = prices.find((p) => p.zone_id === zone_id)?.price || 0;

               if (price) {
                  map.setFeatureState(
                     {
                        source: layerId,
                        sourceLayer: layerId,
                        id: feature.id,
                     },
                     { price: price },
                  );
               }
            });
         }
      });
   }, [map, prices]);

   const exp = useMemo(() => minmax && getLegendColorExpression(minmax[0], minmax[1], ['feature-state', 'price']), [minmax]);

   if (!map) return null;

   return (
      <>
         <Source id={layerId} type="vector" url={sourceUrl}>
            <Layer
               id={layerId} type="fill" source={layerId}
               source-layer={sourceLayer} layout={FUTURE_ZONE_STYLE.FILL_LAYOUT} paint={FUTURE_ZONE_STYLE.FILL_PAINT(exp)}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} interactive />
            <Layer
               id={`${layerId}-border`} type="line" source={layerId}
               source-layer={sourceLayer} layout={FUTURE_ZONE_STYLE.LINE_LAYOUT} paint={FUTURE_ZONE_STYLE.LINE_PAINT}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} />
            {/* <Layer
               id={`${layerId}-label`} type="symbol" source={layerId}
               source-layer={sourceLayer} layout={FUTURE_ZONE_STYLE.TITLE_LAYOUT} paint={FUTURE_ZONE_STYLE.TITLE_PAINT}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} /> */}
         </Source>
      </>
   );
};

export default FutureZoneLayer;
