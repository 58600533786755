import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { GenerationPocketDescription, GenerationPocketRegionDescription } from 'power/types';
import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { GPLayers } from '../type';
import styles from './SiderBottom.module.scss';

const REGION_NAMES = ['Western New York', 'North Country', 'Capital Region', 'Southern Tier'];

type Props = {
  mapId: string;
  gpLayer: GPLayers;
  open: boolean;
  onClose: () => void;
  selectedPocket: number | null;
  pockets: { id: number; name: string; regionId: number }[];
  descriptionPocket: GenerationPocketDescription[];
  descriptionRegion: GenerationPocketRegionDescription[];
};

const SiderBottom: React.FC<Props> = ({ mapId, gpLayer, open, onClose, selectedPocket, pockets, descriptionPocket, descriptionRegion }) => {
   const { [mapId]: map } = useMap();

   useEffect(() => {
      if (map) {
         setTimeout(() => {
            map.resize();
         }, 400);
      }
   }, [map, open]);

   const pocketName = pockets.find((p) => p.id === selectedPocket)?.name ?? '';
   const regionId = pockets.find((p) => p.id === selectedPocket)?.regionId ?? 0;
   const descriptionPocketSelectedItems = descriptionPocket.filter((d) => d.generation_pocket_id === selectedPocket && (gpLayer.year2030 && gpLayer.year2035 ? true : gpLayer.year2030 ? d.target_year === 2030 : gpLayer.year2035 ? d.target_year === 2035 : false));
   const descriptionRegionSelectedItems = descriptionRegion.filter((d) => d.generation_pocket_region_id === regionId && (gpLayer.year2030 && gpLayer.year2035 ? true : gpLayer.year2030 ? d.target_year === 2030 : gpLayer.year2035 ? d.target_year === 2035 : false));

   const descriptionPocketSelected = descriptionPocketSelectedItems.reduce<{ year: number; description: string }[]>((acc, d) => {
      if (d.description) d.description.forEach((desc) => acc.push({ year: d.target_year, description: desc }));
      return acc;
   }, []);

   const descriptionRegionSelected = descriptionRegionSelectedItems.reduce<{ year: number; description: string }[]>((acc, d) => {
      if (d.description) d.description.forEach((desc) => acc.push({ year: d.target_year, description: desc }));

      return acc;
   }, []);

   if (!open || !selectedPocket) return null;

   return (
      <>
         <div className={styles.descContainer}>
            <div className={styles.closeBtn}>
               <Button size="small" icon={<CloseOutlined />} onClick={() => onClose()} />
            </div>
            <div className={styles.scroll}>
               {descriptionRegionSelected.length > 0 ? (
                  <div className={styles.desc}>
                     <div className={styles.title}>{REGION_NAMES[regionId - 1]}</div>
                     <ul className={styles.content}>
                        {descriptionRegionSelected.map((d, i) => (
                           <li key={i}>
                              <b>({d.year})</b> {d.description}
                           </li>
                        ))}
                     </ul>
                  </div>
               ) : null}

               {descriptionPocketSelected.length > 0 ? (
                  <div className={styles.desc}>
                     <div className={styles.title}>Pocket {pocketName}</div>
                     <ul className={styles.content}>
                        {descriptionPocketSelected.map((d, i) => (
                           <li key={i}>
                              <b>({d.year})</b> {d.description}
                           </li>
                        ))}
                     </ul>
                  </div>
               ) : null}
            </div>
         </div>
      </>
   );
};

export default SiderBottom;
