import { ApiClients } from 'ApiClients';
import PATHS from 'power/PATHS';
import { useAuth } from 'providers/AuthProvider';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInForm from '../SignInForm/SignInForm';
import styles from './SignInPage.module.scss';

const SignInPage = () => {
   const [loading, setLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

   const { authenticated, loggedIn } = useAuth();
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (authenticated) navigate(PATHS.Index, { replace: true, state: location.state });
   }, [authenticated, location.state, navigate]);

   const onSignIn = useCallback(
      async (email: string, password: string) => {
         setLoading(true);
         setErrorMessage(undefined);
         try {
            const user = await ApiClients.getInstance().login(email, password);
            if (user === undefined) throw new Error('Invalid email or password');
            else loggedIn(user);
         } catch (error: any) {
            console.log('error', error);
            setErrorMessage('Invalid email or password');
            setLoading(false);
         }
      },
      [loggedIn],
   );

   return (
      <div className={styles.content}>
         <div className={styles.form}>
            <div className={styles.logo} />
            <h2 className={styles.title}>Sign In</h2>
            <SignInForm
               loading={loading}
               onSignIn={onSignIn}
               errorMessage={errorMessage}
               onForgot={() => {
                  navigate(PATHS.Auth_ForgetPassword);
               }}
            />
         </div>
      </div>
   );
};

export default SignInPage;
