import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import { GenerationPockets } from 'modules/GenerationPockets';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import TlPopup from 'power/map/layers/StaticLayers/TransmissionLinesLayer/popup/TlPopup';
import { PowerMapProvider } from 'power/map/usePowerMap';
import { DATA_PROVIDER, FUEL_TYPE, SUBSCRIPTION_PACKAGE, TL_VOLTAGE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useIso } from 'providers/useIso';
import ReactGA from 'react-ga4';

const INITIAL_BOUNDS: mapboxgl.LngLatBoundsLike = [-70.96, 45.8, -80.0, 39.98];
const INITIAL_MAP_STYLE: 'Default' | 'Satellite' = 'Default';

export const GenerationPocketsPage = () => {
   ReactGA.send({ hitType: 'pageview', page: '/generation-pockets', title: 'Generation Pockets' });
   const { iso } = useIso();
   const { isos, hasPackage } = useAuth();

   if (isos.length === 0 || iso === DATA_PROVIDER.Undefined) return null;

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Renewable Generation Pockets" />}>
         <PowerMapProvider
            initialState={{
               bounds: INITIAL_BOUNDS,
               mapStyle: INITIAL_MAP_STYLE,
               staticLayers: {
                  allActive: false,
                  defaultCollapsed: false,
                  iq: hasPackage(iso, SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE) ? { filter: { active: false } } : undefined,
                  powerPlants: hasPackage(iso, SUBSCRIPTION_PACKAGE.POWER_PLANT) ? { filter: { active: false, fuelTypes: [FUEL_TYPE.Undefined] } } : undefined,
                  transmissionLines: hasPackage(iso, SUBSCRIPTION_PACKAGE.TRANSMISSION_LINE)
                     ? {
                        filter: { active: false, voltages: [TL_VOLTAGE['345 - 499'], TL_VOLTAGE['500+']] },
                        popup: TlPopup as any,
                     }
                     : undefined,
               },
            }}
         >
            <GenerationPockets />
         </PowerMapProvider>
      </PrivatePageLayout>
   );
};
