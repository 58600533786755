import { Checkbox, Flex, Tree } from 'antd';
import { GPLayers } from 'modules/GenerationPockets/type';
import { IMAGE_PATHS } from 'power/constants';
import BaseSection from 'power/map/controls/SiderRightControl/base/BaseSection';
import React, { PropsWithChildren, useCallback } from 'react';
import classes from './GenerationPocketControlSection.module.scss';

type Props = {
  mapId: string;
  gpLayer: GPLayers;
  setGpLayer: React.Dispatch<React.SetStateAction<GPLayers>>;
};

const GenerationPocketControlSection: React.FC<PropsWithChildren<Props>> = ({ mapId, gpLayer, setGpLayer }) => {
   const changeActive = useCallback(() => {
      setGpLayer(
         (prev) => prev && {
            ...prev,
            active: !prev.active,
         },
      );
   }, [setGpLayer]);

   return (
      <BaseSection
         mapId={mapId} active={gpLayer.active ?? false} setActive={changeActive}
         title="Renewable Generation Pockets" defaultCollapsed={gpLayer.defaultCollapsed} loading={gpLayer.loading}>
         <div className={classes.capacityControl}>
            <h3 style={{ margin: '6px 0px 0px', textAlign: 'center' }}>Years</h3>
            <Flex justify="space-around" align="center" style={{ paddingTop: 8 }}>
               <Checkbox.Group
                  // year2030 and year2035 are boolean values as key names
                  value={Object.keys(gpLayer)
                     .filter((key) => key === 'year2030' || key === 'year2035')
                     .filter((key) => (gpLayer as any)[key])}
                  onChange={(values) => {
                     setGpLayer((prev) => prev && { ...prev, year2030: values.includes('year2030'), year2035: values.includes('year2035') });
                  }}
               >
                  <Checkbox value={'year2030'}>2030</Checkbox>
                  <Checkbox value={'year2035'}>2035</Checkbox>
               </Checkbox.Group>
            </Flex>
            <Flex vertical={false}>
               <Checkbox.Group
                  value={Object.keys(gpLayer.visible).filter((key) => (gpLayer.visible as any)[key])}
                  onChange={(values) => {
                     setGpLayer((prev) => prev && { ...prev, visible: { ...prev.visible, plant: values.includes('plant'), largeLoad: values.includes('largeLoad'), constraint: values.includes('constraint'), tlUpgrade: values.includes('tlUpgrade'), nodes: values.includes('nodes') } });
                  }}
                  style={{ width: '100%', margin: 6 }}
               >
                  <Flex vertical gap={6} style={{ width: '100%' }}>
                     <h4 style={{ margin: 0, textAlign: 'start' }}>Layers</h4>
                     <Checkbox value="constraint">
                        <Flex
                           justify="center" align="center" gap={6}
                           style={{ paddingTop: 2 }}>
                           <img height={14} src={IMAGE_PATHS.MAP_ASSETS.FILE('arrow-right.png')} alt="constraint" /> Constraints
                        </Flex>
                     </Checkbox>

                     <Checkbox value="plant">Renewable Resources</Checkbox>
                     {gpLayer.visible.plant && (
                        <>
                           <Tree
                              checkable
                              defaultExpandAll
                              selectable={false}
                              checkedKeys={gpLayer.filter.plant}
                              onCheck={(checkedKeys) => {
                                 setGpLayer((prev) => prev && { ...prev, filter: { ...prev.filter, plant: checkedKeys as any } });
                              }}
                              treeData={[
                                 {
                                    title: 'Solar',
                                    key: 'Solar',
                                    children: [
                                       { title: <IconText type="solar" subType="cc" />, key: 'Solar-cc' },
                                       { title: <IconText type="solar" subType="lo" />, key: 'Solar-lo' },
                                       { title: <IconText type="solar" subType="hi" />, key: 'Solar-hi' },
                                       { title: <IconText type="solar" subType="hilo" />, key: 'Solar-hilo' },
                                    ],
                                 },
                                 {
                                    title: 'Wind',
                                    key: 'Wind',
                                    children: [
                                       { title: <IconText type="wind" subType="cc" />, key: 'Wind-cc' },
                                       { title: <IconText type="wind" subType="lo" />, key: 'Wind-lo' },
                                       { title: <IconText type="wind" subType="hi" />, key: 'Wind-hi' },
                                       { title: <IconText type="wind" subType="hilo" />, key: 'Wind-hilo' },
                                    ],
                                 },
                              ]}
                           />
                        </>
                     )}
                     <Checkbox value="largeLoad">
                        <Flex
                           justify="center" align="center" gap={4}
                           style={{ paddingTop: 2 }}>
                           <img height={16} src={IMAGE_PATHS.MAP_ASSETS.FILE('large-load.png')} alt="Large Loads" /> Large Loads
                        </Flex>
                     </Checkbox>

                     <Checkbox value="tlUpgrade">
                        <Flex
                           justify="center" align="center" gap={6}
                           style={{ paddingTop: 2 }}>
                           <img height={14} src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/tl2.png')} alt="Transmission Upgrades" /> Transmission Upgrades
                        </Flex>
                     </Checkbox>

                     <Checkbox value="nodes">
                        <Flex
                           justify="center" align="center" gap={6}
                           style={{ paddingTop: 2 }}>
                           {/* <img height={14} src={IMAGE_PATHS.MAP_ASSETS.FILE('price/node.png')} alt="Nodes" /> */}
                           <div className={classes.nodeImg}></div>
                  Nodes
                        </Flex>
                     </Checkbox>
                  </Flex>
               </Checkbox.Group>
            </Flex>
         </div>
      </BaseSection>
   );
};

const IconText = ({ type, subType }: { type: 'solar' | 'wind'; subType: 'cc' | 'lo' | 'hi' | 'hilo' }) => (
   <Flex
      justify="center" align="center" gap={6}
      style={{ marginLeft: -3 }}>
      <div style={{ backgroundColor: type === 'solar' ? '#feff00' : '#14e0f1', width: 20, height: 20, borderRadius: '50%', border: '1px solid #000000', lineHeight: subType === 'hilo' ? '16px' : '18px', textAlign: 'center', color: '#000000' }}>
         {subType === 'cc' ? '' : subType === 'lo' ? 'L' : subType === 'hi' ? 'H' : '+'}
      </div>
      <span>{subType === 'cc' ? 'Awarded Resource' : subType === 'lo' ? 'Lower' : subType === 'hi' ? 'Higher' : 'Lower + Higher'}</span>
   </Flex>
);

export default GenerationPocketControlSection;
