import { BarChartOutlined, GroupOutlined, LineChartOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { Svg } from 'power/components/base/svg';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ArbitrageTab from './arbitrage';
import BasisRiskTab from './basis-risk';
import LmpTab from './lmp';
import MccTab from './mcc';
import OverviewTab from './overview';
import TbxTab from './tbx';

type Props = {
  query: Partial<INodeInfoRequest>;
  data: INodeInfoResponse;
};

const TabsContent: FC<Props> = ({ query, data }) => {
   const { hash } = useLocation();

   const setActiveKey = (key: string) => {
      window.location.hash = key;
   };

   const items: TabsProps['items'] = useMemo(() => {
      const tabProps: TabsProps['items'] = [
         {
            key: '#overview',
            label: 'Overview',
            children: <OverviewTab query={query} data={data} />,
         },
         {
            key: '#lmp',
            label: 'LMP',
            children: <LmpTab query={query} data={data} />,
            icon: <BarChartOutlined />,
         },
         {
            key: '#tbx',
            label: 'TBX',
            children: <TbxTab query={query} data={data} />,
            icon: <GroupOutlined />,
         },
         {
            key: '#arbitrage',
            label: 'Arbitrage',
            children: <ArbitrageTab query={query} data={data} />,
            icon: <GroupOutlined />,
         },
         {
            key: '#mcc',
            label: 'Constraint & MCC',
            children: <MccTab query={query} data={data} />,
            icon: <LineChartOutlined />,
         },
         {
            key: '#basis-risk',
            label: 'Basis Risk',
            children: <BasisRiskTab query={query} data={data} />,
            icon: <Svg.Basisrisk />,
         },
         /* {
            key: '#ancillary',
            label: 'Ancillary',
            children: 'Content of Ancillary',
         }, */
      ];

      data.node.disabledTabs.forEach((tab) => {
         const index = tabProps.findIndex((item) => item.key === `#${tab}`);
         if (index > -1) {
            tabProps.splice(index, 1);
         }
      });
      
      return tabProps;
   }, [query, data]);

   return (
      <Tabs
         style={{ paddingBottom: 32 }} activeKey={!!hash ? hash : items[0].key} centered
         type="card" items={items} onChange={setActiveKey} />
   );
};

export default TabsContent;
