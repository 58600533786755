import { ApiClients } from 'ApiClients';
import { FeatureCollection, Point } from 'geojson';
import { IMAGE_PATHS } from 'power/constants';
import addMapImages from 'power/map/helper/addMapImages';
import { INodeLayerProperties } from 'power/map/layers/NodeLayer/type';
import { INodeMapPageQuery } from 'power/types';
import { MARKET_TYPE, NODE_MAP_DATA_COMPONENT, PERIOD_TYPE, PRICE_COMPONENT, TBX_VALUE_TYPE, TXBX_COMPONENT } from 'power/types/enum';
import { useIso } from 'providers/useIso';
import React, { useEffect } from 'react';
import { Layer, Popup, Source, useMap } from 'react-map-gl';

export const GENERATION_POCKETS_NODES_LAYER_ID = 'GENERATION_POCKETS_NODES';

const DEFAULT_NODE_QUERY: Partial<INodeMapPageQuery> = {
   component: NODE_MAP_DATA_COMPONENT.LMP,
   marketType: MARKET_TYPE.DayAhead,
   periodType: PERIOD_TYPE.last_2_year,
   startDate: '2024-01-01',
   endDate: '2024-02-01',
   tbxType: TXBX_COMPONENT.Tb4,
   tbxValueType: TBX_VALUE_TYPE.Performance,
   durationHour: 4,
   sitingValueType: PRICE_COMPONENT.BATTERY_PROFIT_PER_MW,
   showAll: false,
};

const NODE_IMAGES: { name: string; url: string }[] = [{ name: 'Node-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.NODE_SDF }];

type Props = {
  mapId: string;
};

const NodesLayer: React.FC<Props> = ({ mapId }) => {
   const { iso } = useIso();
   const { [mapId]: map } = useMap();
   const [geojson, setGeojson] = React.useState<FeatureCollection<Point, INodeLayerProperties>>();

   const [loadingImage, setLoadingImage] = React.useState<boolean>(true);
   const [tooltip, setTooltip] = React.useState<{ nodeName: string; lon: number; lat: number } | null>(null);

   useEffect(() => {
      const loadImgaes = () => {
         if (map === undefined) return;
         setLoadingImage(true);
         addMapImages(map, NODE_IMAGES, true).then(() => setLoadingImage(false));
      };

      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [map]);

   useEffect(() => {
      if (!map) return;
      console.log('NodesLayer useEffect', DEFAULT_NODE_QUERY, iso);
      ApiClients.getInstance()
         .mapNode({ ...DEFAULT_NODE_QUERY, provider: iso })
         .then((data) => {
            setGeojson(data);
         });
   }, [iso, map]);

   /**
   * Show node name popup on mouse move
   */
   useEffect(() => {
      if (!map) return;

      map.on('mousemove', (e) => {
         if (!map.getLayer(GENERATION_POCKETS_NODES_LAYER_ID)) return;
         const features = map.queryRenderedFeatures(e.point, { layers: [GENERATION_POCKETS_NODES_LAYER_ID] });
         if (features.length) {
            const feature = features[0];
            const nodeName = feature.properties?.name;
            const coordinates = e.lngLat;
            if (nodeName) {
               // console.log(nodeName);
               setTooltip({ nodeName: nodeName, lon: coordinates.lng, lat: coordinates.lat });
            }
         } else {
            setTooltip(null);
         }
      });

      return () => {
         map.off('mousemove', () => {});
      };
   }, [map]);

   if (!map || !geojson || !loadingImage) return null;

   return (
      <>
         <Source id={GENERATION_POCKETS_NODES_LAYER_ID} type="geojson" data={geojson}>
            <Layer
               id={GENERATION_POCKETS_NODES_LAYER_ID}
               type="symbol"
               layout={{
                  'icon-image': 'Node-image-powerdev',
                  'icon-size': 0.2,
                  'icon-allow-overlap': true,
               }}
               paint={{
                  /* 'icon-color': '#6cc3a0' */
                  'icon-color': '#a4a1a1',
                  /* 'icon-color': '#000' */
               }}
            />
         </Source>
         {tooltip && (
            <Popup
               className="auto-width" closeButton={false} closeOnClick={false}
               latitude={tooltip.lat} longitude={tooltip.lon}>
               <div>{tooltip.nodeName}</div>
            </Popup>
         )}
      </>
   );
};

export default NodesLayer;
