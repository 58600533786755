import { Flex } from 'antd';
import { GenerationPocketAverageCurtailment } from 'power/types';
import { FC, useMemo } from 'react';
import classes from './ChartView.module.scss';
import PocketAverageChart from './PocketAverageChart';
// import PocketScatterChart from './PocketScatterChart';

type Props = {
  pockets: { id: number; name: string }[];
  averageCurtailments: GenerationPocketAverageCurtailment[];
};

const ChartView: FC<Props> = ({ pockets, averageCurtailments }) => {
   const fullData = useMemo(
      () => averageCurtailments.map((d) => {
         const pocketName = pockets.find((p) => p.id === d.generation_pocket_id)?.name ?? '';
         return {
            ...d,
            pocketName,
         };
      }),
      [averageCurtailments, pockets],
   );

   // Pockets' Pocket Average
   const data1 = useMemo(() => fullData.filter((x) => x.type === 'Pocket Average').sort((a, b) => a.generation_pocket_id - b.generation_pocket_id), [fullData]);

   // Wind types averages
   const data2 = useMemo(() => fullData.filter((x) => x.type === 'Wind').sort((a, b) => a.generation_pocket_id - b.generation_pocket_id), [fullData]);

   // solar types averages
   const data3 = useMemo(() => fullData.filter((x) => x.type === 'Solar').sort((a, b) => a.generation_pocket_id - b.generation_pocket_id), [fullData]);

   // Hydro types averages
   const data4 = useMemo(() => fullData.filter((x) => x.type === 'Hydro').sort((a, b) => a.generation_pocket_id - b.generation_pocket_id), [fullData]);

   // console.log('fullData', fullData);
   // console.log('data1', data1);
   // console.log('data2', data2);

   return (
      <div className={classes.container}>
         <Flex vertical justify="center" align="center">
            <PocketAverageChart title="Average Curtailment - Pocket Average" data={data1} seriesField="case_type" />
            <PocketAverageChart title="Average Curtailment - Hydro Average" data={data4} seriesField="case_type" />
            <PocketAverageChart title="Average Curtailment - Solar Average" data={data3} seriesField="case_type" />
            <PocketAverageChart title="Average Curtailment - LBW Average" data={data2} seriesField="case_type" />
            {/* <PocketScatterChart title="Scatter Chart - All Averages" data={fullData} /> */}
         </Flex>
      </div>
   );
};

export default ChartView;
