import { SyncOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import { SelectNodev2 } from 'power/components/filter';
import { INodeInfoRequest } from 'power/types';
import { useIso } from 'providers/useIso';
import { FC, useCallback, useEffect, useState } from 'react';

type Props = {
  loading: boolean;
  query: Partial<INodeInfoRequest>;
  onSubmit: (filter: Partial<INodeInfoRequest>) => void;
};

const QueryBar: FC<Props> = ({ loading, query, onSubmit }) => {
   const { iso } = useIso();
   const [formQuery, setFormQuery] = useState<Partial<INodeInfoRequest>>(query);

   const onChange = useCallback((partialFilter: Partial<INodeInfoRequest>) => {
      setFormQuery((prev) => ({ ...prev, ...partialFilter }));
   }, []);

   useEffect(() => {
      setFormQuery(query);
   }, [query]);

   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   const selectNodeFethcer = useCallback(() => ApiClients.getInstance().nodeList({ iso }), [iso]);

   return (
      <Form layout="vertical" name="node_dashboards_form">
         <div className="filter-bar-container">
            <div className="filter-bar-row">
               <div className="filter-bar-col">
                  <PowerRow gutter={[16, 16]}>
                     <PowerCol xs={24} sm={24} lg={7}>
                        <Form.Item label="Node" rules={[{ required: true }]}>
                           <SelectNodev2
                              value={formQuery.nodeId} fetcher={selectNodeFethcer} onChange={(value) => onChange({ nodeId: value })}
                              disabled={loading} />
                        </Form.Item>
                     </PowerCol>

                     <PowerCol xs={24} sm={12} lg={6}>
                        <Button
                           className="btn-filter-bar-green" type="primary" size="large"
                           icon={<SyncOutlined />} onClick={onFormSubmit} loading={loading}>
                  Reload
                        </Button>
                     </PowerCol>
                  </PowerRow>
               </div>
            </div>
         </div>
      </Form>
   );
};

export default QueryBar;
