import { Menu } from 'antd';
import { DATA_PROVIDER } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useIso } from 'providers/useIso';
import React, { useMemo } from 'react';
import { useLocation /* , useNavigate */ } from 'react-router-dom';
import menuItems from './menuItems';

type Props = {
  setCollapsedMenu: (newCollapsed: boolean) => void;
};

export const NavigatorMenu: React.FC<Props> = ({ setCollapsedMenu }) => {
   const { iso } = useIso();
   const location = useLocation();
   const { hasPackage } = useAuth();

   const selectedKeys = useMemo(() => {
      const isoName = DATA_PROVIDER[iso].toLowerCase();
      let menuPath = location.pathname.replace(`/${isoName}`, '');
      if (menuPath.startsWith('/')) {
         menuPath = menuPath.substring(1);
      }
      return [menuPath];
      // [location.pathname.split('/').filter(Boolean).join('/')],
      /* const keys = router.pathname.split('/').filter(Boolean);
        keys.push(keys.join('/'));
        return keys; */
   }, [iso, location.pathname]);

   const onMenuItemClick = (key: string) => {
      /**
     * TODO if need
     */

      // console.log("onMenuItemClick", key)
      // navigate(`${key}`);
      setCollapsedMenu(true);
   };

   const userMenuItems = useMemo(() => menuItems?.filter((item) => !(item as any).subscriptionpackage || hasPackage(iso, (item as any).subscriptionpackage)), [hasPackage, iso]);

   return (
      <Menu
         selectedKeys={selectedKeys}
         onClick={(e) => {
            onMenuItemClick(e.key);
         }}
         mode="inline"
         theme="light"
         items={userMenuItems}
         inlineIndent={12}
         style={{ borderInlineEnd: 'none' }}
      />
   );
};
