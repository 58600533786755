const PATHS = {
   Auth_Login: '/auth/login',
   Auth_Logout: '/auth/logout',
   Auth_ForgetPassword: '/auth/forgot-password',
   Auth_ResetPassword: '/auth/reset-password',
   Auth_ActivateAccount: '/auth/register',

   Index: '/',
   Home: '',
   MapPage: 'map',
   NodeAnalysis: 'node/analysis',
   TransmissionCapacity: 'transmission-capacity',
   IQ_List: 'iq/list',
   ICAP: 'icap',
   Future: 'future',
   FutureHeatmap: 'future/heatmap',
   FutureMap: 'future/map',
   GenerationPockets: 'renewable-generation-pockets',
   News: 'news',

   Test: 'test',
   HistoricalHeatmap: 'historical/heatmap',

   Auth_ChangePassword: 'auth/change-password',
   Support: 'support',
   Mobile: 'mobile',
};

export default PATHS;
