import { ApiClients } from 'ApiClients';
import { GenerationPocketBindingContingency } from 'power/types';
import { useEffect, useState } from 'react';

export const useBindingContingencies = () => {
   const [loadingBindingContingencies, setLoadingBindingContingencies] = useState<boolean>(true);
   const [bindingContingencies, setBindingContingencies] = useState<GenerationPocketBindingContingency[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketBindingContingencyList()
         .then((res) => {
            setBindingContingencies(res);
         })
         .finally(() => setLoadingBindingContingencies(false));
   }, []);

   return { loadingBindingContingencies, bindingContingencies };
};
