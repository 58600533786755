import { DATA_PROVIDER } from 'power/types/enum';
import { CustomLayerProps, ErcotZoneLayerProperties, IBaseFilter, IsoneZoneLayerProperties, /* IZoneFilter, */ NyisoZoneLayerProperties } from 'power/types/map';
import { useIso } from 'providers/useIso';
import React, { useMemo } from 'react';
import { MapRef } from 'react-map-gl';
import ErcotZoneLayer from './ErcotZoneLayer';
import IsoneZoneLayer from './IsoneZoneLayer';
import NyisoZoneLayer from './NyisoZoneLayer';

export const ZONE_DEFAULT_FILTER: IBaseFilter = {
   active: false,
};

type Props = {
  map: MapRef;
  layerProps: CustomLayerProps<NyisoZoneLayerProperties | IsoneZoneLayerProperties | ErcotZoneLayerProperties, IBaseFilter>;
  beforeId?: string;
};

const ZoneLayer: React.FC<Props> = ({ map, layerProps, beforeId }) => {
   const { iso } = useIso();

   const filter = useMemo(() => layerProps?.filter || ZONE_DEFAULT_FILTER, [layerProps]);

   if (!filter.active) return null;

   if (iso === DATA_PROVIDER.NYISO) {
      return <NyisoZoneLayer map={map} layerProps={layerProps as any} beforeId={beforeId} />;
   }

   if (iso === DATA_PROVIDER.ISONE) {
      return <IsoneZoneLayer map={map} layerProps={layerProps as any} beforeId={beforeId} />;
   }

   if (iso === DATA_PROVIDER.ERCOT) {
      return <ErcotZoneLayer map={map} layerProps={layerProps as any} beforeId={beforeId} />;
   }

   return null;
};

export default ZoneLayer;
