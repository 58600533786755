import type { MenuProps } from 'antd';
import { MENU_ITEM_PARAMS } from 'power/constants';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import React from 'react';
import { Link } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group', disabled?: boolean, className?: string, subscriptionpackage?: SUBSCRIPTION_PACKAGE): MenuItem => ({
   key,
   icon,
   children,
   label,
   type,
   disabled,
   className,
   subscriptionpackage,
}) as MenuItem;

const menuItems: MenuProps['items'] = [];

MENU_ITEM_PARAMS.forEach((mip) => {
   if (mip.divider) {
      menuItems.push({ type: 'divider' });
   } else {
      let menuItem: MenuItem;

      if (mip.children && mip.children.length > 0) {
         const children: MenuItem[] = [];
         const itemTitle: MenuItem = getItem(mip.label, mip.keyPath, undefined, undefined, mip.type, mip.disabled, 'sidebar-menu-title', mip.subscriptionPackage);
         children.push(itemTitle);
         mip.children.forEach((c) => {
            const item: MenuItem = getItem(
               c.disabled ? (
                  c.label
               ) : (
                  <Link to={c.keyPath} rel="noopener noreferrer">
                     {c.label}
                  </Link>
               ),
               c.keyPath,
               c.icon,
               undefined,
               undefined,
               c.disabled,
               undefined,
               c.subscriptionPackage
            );
            children.push(item);
         });
         menuItem = getItem(mip.label, mip.keyPath, mip.icon, children, undefined, mip.disabled);
      } else {
         menuItem = getItem(
            <Link to={mip.keyPath} rel="noopener noreferrer">
               {mip.label}
            </Link>,
            mip.keyPath,
            mip.icon,
            undefined,
            mip.type,
            mip.disabled,
            undefined,
            mip.subscriptionPackage
         );
      }

      menuItems.push(menuItem);
   }
});

export default menuItems;
