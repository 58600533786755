import { useContent } from 'content';
import LazyComponent from 'power/components/base/LazyComponent';
import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { PRICE_COMPONENT } from 'power/types/enum';
import { FC, /* lazy, */ memo } from 'react';
import DARTSpreadTimeSeries from './components/DARTSpreadTimeSeries';
// import HistoricalAVGPrices from './components/HistoricalAVGPrices';
import InfoBoxes from './components/InfoBoxes';
import STDTimeSeries from './components/STDTimeSeries';
import TimeSeries from './components/TimeSeries';
// import WeightedSeries from './components/WeightedSeries';
import HistoricalAVGPrices from './components/HistoricalAVGPrices';
import WeightedSeries from './components/WeightedSeries';
import YearlySum from './components/YearlySum';

type Props = {
   query: Partial<INodeInfoRequest>;
  data: INodeInfoResponse;
};

const LmpTab: FC<Props> = ({ query, data }) => {
   const { t } = useContent();

   return (
      <PowerRow gutter={[16, 16]}>
         <PowerCol
            xs={24} sm={24} md={24}
            lg={24}>
            <InfoBoxes nodeId={data.node.id} />
         </PowerCol>
         <PowerCol
            xs={24} sm={24} md={16}
            lg={16}>
            <LazyComponent>
               <DARTSpreadTimeSeries nodeId={data.node.id} provider={data.node.provider} />
            </LazyComponent>
         </PowerCol>

         <PowerCol
            xs={24} sm={24} md={8}
            lg={8}>
            <LazyComponent>
               <YearlySum
                  nodeId={data.node.id} component={PRICE_COMPONENT.LMP} title={t('lmpOverview.lmpSummary')}
                  info={t('lmpOverview.lmpSummaryChartInfo')} />
            </LazyComponent>
         </PowerCol>

         <PowerCol
            xs={24} sm={24} md={16}
            lg={14}>
            <LazyComponent>
               <TimeSeries nodeId={data.node.id} />
            </LazyComponent>
         </PowerCol>

         <PowerCol
            xs={24} sm={24} md={16}
            lg={10}>
            <LazyComponent>
               <YearlySum
                  nodeId={data.node.id} component={PRICE_COMPONENT.MCC} title={t('lmpOverview.congestionMCCSummary')}
                  info={t('lmpOverview.congestionMCCSummaryChartInfo')} />
            </LazyComponent>
         </PowerCol>

         <PowerCol xs={24} sm={24} md={14}>
            <LazyComponent>
               <WeightedSeries nodeId={data.node.id} />
            </LazyComponent>
         </PowerCol>

         <PowerCol xs={24} sm={24} md={10}>
            <LazyComponent>
               <HistoricalAVGPrices nodeId={data.node.id} />
            </LazyComponent>
         </PowerCol>

         <PowerCol xs={24} sm={24} md={14}>
            <LazyComponent>
               <STDTimeSeries nodeId={data.node.id} />
            </LazyComponent>
         </PowerCol>

         <PowerCol xs={24} sm={24} md={10}>
            <LazyComponent>
               <YearlySum
                  nodeId={data.node.id} component={PRICE_COMPONENT.STDDEV} title={t('lmpOverview.stdDevLmpSummary')}
                  info={t('lmpOverview.stdDevLmpSummaryChartInfo')} />
            </LazyComponent>
         </PowerCol>

         {/* <PowerCol span={24}>
            <LazyComponent>
               <FuelMix nodeId={data.node.id} provider={data.node.provider} />
            </LazyComponent>
         </PowerCol> */}
      </PowerRow>
   );
};

export default memo(LmpTab);
