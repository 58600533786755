import { Button, Layout } from 'antd';
import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import PATHS from 'power/PATHS';
import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

export const PrivateNotFoundPage = () => {
   ReactGA.send({ hitType: 'pageview', page: '404', title: '404' });

   const [redirectTime, setRedirectTime] = useState(4);
   const navigate = useNavigate();

   useEffect(() => {
      const timer = setTimeout(() => {
         setRedirectTime(redirectTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
   }, [redirectTime]);

   const handleHome = useCallback(() => {
      navigate(PATHS.Index);
   }, [navigate]);

   useEffect(() => {
      if (redirectTime === 0) {
         handleHome();
      }
   }, [handleHome, redirectTime]);

   const handleBack = () => {
      navigate(-1);
   };

   return (
      <PrivatePageLayout>
         <Layout style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginBottom: '1rem' }}>
               <span style={{ fontSize: '4rem', fontWeight: 700 }}> 404</span>
            </div>
            <div>
               <span style={{ fontSize: '1.5rem', fontWeight: 400 }}>There is no such page.</span>
            </div>
            <div style={{ padding: '5rem 0', display: 'flex', flexDirection: 'column' }}>
               <Button
                  type="default" size="large" style={{ width: '18rem', borderRadius: '9rem' }}
                  onClick={handleBack}>
            Back
               </Button>
               <br />
               <br />
               <Button
                  type="primary" size="large" style={{ width: '18rem', borderRadius: '9rem' }}
                  onClick={handleHome}>
            Home ({redirectTime})
               </Button>
            </div>
         </Layout>
      </PrivatePageLayout>
   );
};
