import { FeatureCollection, LineString, Point } from 'geojson';
import { GenerationPocketConstraint, GenerationPocketConstraintResponse, GenerationPocketLargeLoad, GenerationPocketPlant, GenerationPocketTransmissionLine } from 'power/types';

export const getConstraintsGeojson = (constraints: GenerationPocketConstraintResponse): FeatureCollection<LineString, GenerationPocketConstraint & { target_year: number }> => {
   const geojson: FeatureCollection<LineString, GenerationPocketConstraint & { target_year: number }> = {
      type: 'FeatureCollection',
      features: [],
   };

   for (const constraint of constraints) {
      for (const c of constraint.constraints) {
         geojson.features.push({
            type: 'Feature',
            properties: { ...c, target_year: constraint.target_year },
            geometry: {
               type: 'LineString',
               coordinates: [c.substations.from_coordinates, c.substations.to_coordinates],
            },
         });
      }
   }

   return geojson;
};

export const getConstraintsArrowLineGeojson = (constraints: GenerationPocketConstraintResponse): FeatureCollection<LineString, GenerationPocketConstraint & { target_year: number }> => {
   const geojson: FeatureCollection<LineString, GenerationPocketConstraint & { target_year: number }> = {
      type: 'FeatureCollection',
      features: [],
   };

   for (const constraint of constraints) {
      for (const c of constraint.constraints) {
         if (c.substations.to_coordinates !== null) {
            geojson.features.push({
               type: 'Feature',
               properties: { ...c, target_year: constraint.target_year },
               geometry: {
                  type: 'LineString',
                  coordinates: [c.substations.from_coordinates, c.substations.to_coordinates],
               },
            });
         }
      }
   }

   return geojson;
};

export const getConstraintsArrowPointGeojson = (constraints: GenerationPocketConstraintResponse): FeatureCollection<Point, GenerationPocketConstraint & { target_year: number; rotate: number }> => {
   const geojson: FeatureCollection<Point, GenerationPocketConstraint & { target_year: number; rotate: number }> = {
      type: 'FeatureCollection',
      features: [],
   };

   for (const constraint of constraints) {
      for (const c of constraint.constraints) {
         if (c.substations.to_coordinates !== null) {
            const toLng = c.substations.to_coordinates[0];
            const toLat = c.substations.to_coordinates[1];

            const fromLng = c.substations.from_coordinates[0];
            const fromLat = c.substations.from_coordinates[1];

            const deltaLat = toLat - fromLat;
            const deltaLng = toLng - fromLng;

            const bearing = (Math.atan2(deltaLat, deltaLng) * 180) / Math.PI;

            const rotateClockwise = ((bearing + 360) % 360) * -1;
            // reverse arrow
            const rotateClockwiseBidirectional = ((bearing + 180 + 360) % 360) * -1;

            geojson.features.push({
               type: 'Feature',
               properties: { ...c, target_year: constraint.target_year, rotate: rotateClockwise },
               geometry: {
                  type: 'Point',
                  coordinates: c.substations.to_coordinates,
               },
            });

            if(c.bidirectional) {
               geojson.features.push({
                  type: 'Feature',
                  properties: { ...c, target_year: constraint.target_year, rotate: rotateClockwiseBidirectional },
                  geometry: {
                     type: 'Point',
                     coordinates: c.substations.from_coordinates,
                  },
               });
            }
         }
      }
   }
   
   /* ({
   type: 'FeatureCollection',
   features: constraints
      .filter((x) => x.arrow_from_location !== null && x.arrow_to_location !== null)
      .map((constraint) => {
         const toLng = constraint.arrow_to_location.coordinates[0];
         const toLat = constraint.arrow_to_location.coordinates[1];

         const fromLng = constraint.arrow_from_location.coordinates[0];
         const fromLat = constraint.arrow_from_location.coordinates[1];

         const deltaLat = toLat - fromLat;
         const deltaLng = toLng - fromLng;

         const bearing = (Math.atan2(deltaLat, deltaLng) * 180) / Math.PI;

         const rotateClockwise = ((bearing + 360) % 360) * -1;

         return {
            type: 'Feature',
            properties: { ...constraint, rotate: rotateClockwise },
            geometry: {
               type: 'Point',
               coordinates: constraint.arrow_to_location.coordinates,
            },
         };
      }),
}); */

   return geojson;
}

export const getTransmissionLinesGeojson = (transmissionLines: GenerationPocketTransmissionLine[]): FeatureCollection<LineString, GenerationPocketTransmissionLine> => ({
   type: 'FeatureCollection',
   features: transmissionLines.map((transmissionLine) => ({
      type: 'Feature',
      properties: transmissionLine,
      geometry: transmissionLine.line_string,
   })),
});

export const getPlantsGeojson = (plants: GenerationPocketPlant[]): FeatureCollection<Point, GenerationPocketPlant> => ({
   type: 'FeatureCollection',
   features: plants.map((plant) => ({
      type: 'Feature',
      properties: plant,
      geometry: plant.location,
   })),
});

export const getLargeLoadsGeojson = (largeLoads: GenerationPocketLargeLoad[]): FeatureCollection<Point, GenerationPocketLargeLoad> => ({
   type: 'FeatureCollection',
   features: largeLoads.map((largeLoad) => ({
      type: 'Feature',
      properties: largeLoad,
      geometry: largeLoad.location,
   })),
});
