// import { PowerMap } from 'power/components/maps';
import { GenerationPocketSummary } from 'power/types';
import { FC, Fragment } from 'react';
import classes from './index.module.scss';

type Props = {
  pockets: { id: number; name: string }[];
  summary: GenerationPocketSummary[];
  targetYear: number;
  case_type: 'PolicyCaseLowerDemand' | 'PolicyCaseHigherDemand' | 'ContractCase' | 'PolicyCaseS1';
  
};

const SummaryTable: FC<Props> = ({ pockets, targetYear, case_type, summary }) => {
   const data = summary.find((x) => x.target_year === targetYear && x.case_type === case_type);

   const title = case_type === 'ContractCase' ? 'Contract Case Summary Pocket Metrics' : case_type === 'PolicyCaseLowerDemand' ? 'Lower Demand Scenario Pocket Summary' : case_type === 'PolicyCaseHigherDemand' ? 'Higher Demand Scenario Pocket Summary' : 'Policy Case S1 Pocket Summary';

   if (!data) return null;

   return (
      <div className={classes.table}>
         <h4 style={{ textAlign: 'center' }}>
            {title} ({data.target_year})
         </h4>
         <table>
            <thead>
               <tr>
                  <th>Pocket</th>
                  <th>Type</th>
                  <th>Capacity (MW)</th>
                  <th>Scheduled Energy (GWh)</th>
                  <th>Curtailment (GWh)</th>
                  <th>Energy Deliverability (%)</th>
               </tr>
            </thead>
            <tbody>
               {pockets.map((pocket, i) => {
                  const pocketData = data.info.filter((info) => info.generation_pocket_id === pocket.id);

                  return (
                     <Fragment  key={`row-${i}-${pocket.id}`}>
                        {pocketData.map((info, j) => (
                           <tr key={`${pocket.id}-${info.type}`}>
                              {j === 0 && <td rowSpan={pocketData.length}>{pocket.name}</td>}
                              <td>{info.type ?? '-'}</td>
                              <td>{info.capacity ?? '-'}</td>
                              <td>{info.scheduled_energy ?? '-'}</td>
                              <td>{info.curtailment ?? '-'}</td>
                              <td>{info.energy_deliverability ?? '-'}</td>
                           </tr>
                        ))}
                     </Fragment>
                  );
               })}
            </tbody>
         </table>
      </div>
   );
};

export default SummaryTable;
