import { ApiClients } from 'ApiClients';
import { GenerationPocketSummary } from 'power/types';
import { useEffect, useState } from 'react';

export const useSummary = () => {
   const [loadingSummary, setLoadingSummary] = useState<boolean>(true);
   const [summary, setSummary] = useState<GenerationPocketSummary[]>([]);

   useEffect(() => {
      ApiClients.getInstance()
         .generationPocketSummary()
         .then((res) => {
            setSummary(res);
         })
         .finally(() => setLoadingSummary(false));
   }, []);

   return { loadingSummary, summary };
}
