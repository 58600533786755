// import { PowerMap } from 'power/components/maps';
import { Flex } from 'antd';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  headers: string[];
  keys: string[];
  rows: { id: string; allValues: (number | string)[][] }[];
};

const TableInfo: FC<Props> = ({ headers, keys, rows }) => {
   /* const getImageUrl = useCallback((type: 'Solar' | 'Wind' | 'Hydro' | 'HQ Imports') => {
      switch (type) {
      case 'Wind':
         return IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Wind);
      case 'Solar':
         return IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Solar);
      case 'Hydro':
         return IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Hydro);
      default:
         return IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Other);
      }
   }, []); */

   if (!rows.length || !keys.length || !rows[0].allValues.length || !rows[0].allValues[0].length) {
      return null;
      /* return (
         <div className={classes.table}>
            <div className={classes.loading}>No data available</div>
         </div>
      ); */
   }

   return (
      <div className={classes.table}>
         <table>
            <thead>
               <tr>
                  <th rowSpan={2}>{headers[0]}</th>
                  <th colSpan={3}>{headers[1]}</th>
                  <th colSpan={3}>{headers[2]}</th>
               </tr>
               <tr>
                  {keys.map((key, index) => (
                     <th key={index}>{key}</th>
                  ))}
                  {keys.map((key, index) => (
                     <th key={index}>{key}</th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {rows.map((row, index) => (
                  <tr key={row.id}>
                     <td>
                        <Flex justify="center" align="center">
                           {/* <img src={getImageUrl(row.id as any)} alt={row.id} style={{height: 24, paddingRight: 4}} />  */}
                           {row.id}
                        </Flex>
                     </td>
                     {row.allValues.map((values, index) => values.map((value, index) => (
                        <td key={index} className={classes.values}>
                           {value}
                        </td>
                     )),
                     )}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default TableInfo;
